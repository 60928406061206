
.flex-chart-card-wrapper {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 0;
  padding-right: 0;

  .flex-chart {
    margin-bottom: -16px;
    margin-left: -8px;
  }
}

.custom-legend-wrapper {
  display: flex;
  justify-content: space-between;
  z-index: 2;

  font-size: 16px;
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: -10px;

  &.small-legend {
    font-size: 0.8em;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 0;
  }
  flex-wrap: wrap;

  .custom-legend-inner {
    display: flex;
    align-items: center;
    color: $text-primary!important;

    .legend-square {
      display: inline-block;
      width: 20px;
      height: 20px;
      border-radius: 2px;

      &-blue {
        background-color: #43AEC5;
      }
      &-pink {
        background-color: $pink;
      }
    }
  }
}

.legend-tooltip-wrapper {
  padding: 10px;
  width: 312px;
}

.legend-tooltip-square {
  min-width: 18px;
  width: 18px;
  height: 18px;
  content: ' ';
}

// Wrapping ration-content with another ratio class, e.g.
// <div class="ratio-7-4"><div class="ratio-content">Content</div></div>
// Makes it so that the content will not stretch the ratio-box.
// This is necessary to make our graphs responsive properly.
.ratio-content {
  position: absolute;
  top: 0; bottom: 0; left: 0; right: 0;
}

.ratio-square {
  position: relative;
  min-width: 100%;
  aspect-ratio: 1 / 1; // CSS Magic! Now to wait until it gets supported by all browsers...
}

@supports not (aspect-ratio: 1 / 1) {
  .ratio-square::before {
    float: left;
    padding-top: 100%;
    content: "";
  }

  .ratio-square::after {
    display: block;
    content: "";
    clear: both;
  }
}

.ratio-7-4 {
  position: relative;
  min-width: 100%;
  aspect-ratio: 7 / 4; // CSS Magic! Now to wait until it gets supported by all browsers...
}
@supports not (aspect-ratio: 7 / 4) {
  .ratio-7-4::before {
    float: left;
    padding-top: 57.14%;
    content: "";
  }

  .ratio-7-4::after {
    display: block;
    content: "";
    clear: both;
  }
}

.ratio-3-2 {
  position: relative;
  min-width: 100%;
  aspect-ratio: 3 / 2; // CSS Magic! Now to wait until it gets supported by all browsers...
}
@supports not (aspect-ratio: 3 / 2) {
  .ratio-3-2::before {
    float: left;
    padding-top: 66.67%;
    content: "";
  }

  .ratio-3-2::after {
    display: block;
    content: "";
    clear: both;
  }
}