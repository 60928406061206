
@import "@/assets/scss/_loaders/_colors.scss";

.preview-block {
  transition: border-color 0.3s ease;
}

.container {
  position: relative;
  overflow: hidden;
  transition: max-height 0.3s ease;
  padding: 0;
}

.truncated-content {
  overflow: auto;
  padding: 5px;
}

.fade-out {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 30px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  pointer-events: none;
}

.preview-block:hover .title-collapsible {
  background-color: $grey-lightest;
}
