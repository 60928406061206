.input-tag-wrapper {
  overflow: hidden;
  cursor: text;
  text-align: left;
  height: auto;

  .input-tag {
    background-color: $blue-transparent;
    border-radius: $border-radius;
    border: $border-blue-1;
    color: $blue-dark;
    display: inline-block;
    margin-top: 4px;
    margin-bottom: 4px;
    margin-right: 4px;
    padding: 3px;
    font-size: 0.9em;
  }

  .is-hover {
    &:hover {
      font-weight: 600;
      text-decoration: none;
    }
  }

  .new-tag {
  color: $text-primary;
    border: 0;
    outline: none;
    width: 50%;
  }

  .read-only {
    cursor: default;
  }
}

.input-tag-wrapper.is-invalid ~ .invalid-feedback {
  display: block;
}
