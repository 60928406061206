.opportunity-card {
  .pp_missing, .pp_avatar {
    width: 40px;
    height: 40px;
    overflow: hidden;
    background: $grey-lightest;
    color: $white;
    border-radius: $border-radius;
  }

  .pp_missing {
    border: $border-base-1;
    font-size: 1.2em;
    width: 41px;
    height: 41px;
    line-height: 40px;
  }
}

.opportunity {
	.thumbnail-block {
		.pp_avatar, .pp_missing {
		  top: calc(0.5rem + 10px) !important;
    }

    .avatar {
      width: 40px;
      height: 40px;
    }

    .name {
      width: calc(100% - 40px);
    }

    .pp_missing, .pp_avatar {
      height: 40px !important;
      width: 40px !important;
      top: calc(0.5rem + 10px) !important;
    }

    .pp_missing {
      line-height: 41px !important;
      background: $grey-lightest;
    }

    .pp_avatar {
      line-height: 38px !important;
    }
  }

  .expertise {
    min-height: 150px;
  }


  .text-upper-left-from-card {
    position: absolute;
    top: -30px;
    font-size: 0.9em;
    color: $white;
  }

  .action-buttons {
    .cancel {
      position: absolute;
      right: 0;
      top: 10px;
    }

    .back {
      position: absolute;
      left: 0;
      top: 10px;
    }
  }
}