.sub_tabs {
  nav.nav {
    box-shadow: $box-shadow-bottom;
    box-sizing: border-box;
    position: relative;
    min-height: $nav-height;
    max-height: $nav-height;
    background: $white;
    text-align: center;
  }

  &_nav {
    &_item {
      cursor: pointer;
      display: inline-block;
      margin-right: 40px;
      position: relative;

      @include respondToWidth(m) {
        margin-right: 10px;
      }
    }

    &_link {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $text-primary;
      font-family: inherit;
      font-size: 1.1em;
      font-weight: normal;
      padding: 4px 0px;
      margin: 10px 0px;
      line-height: calc(#{$nav-height} - 0px);
      max-width: 100%;
      user-select: none;
      transition: 0.20s padding ease-out;
      transition: 0.20s line-height ease-out;

      &:hover {
        border-bottom: $border-blue-2;
        padding-bottom: 16px;
        text-decoration: none;
      }

      &:focus {
        outline: 0;
        color: $text-secondary;
      }
    }

    &_link.active {
      color: $blue;
      border-bottom: $border-blue-2;
      padding-bottom: 16px;

      @include respondToWidth(m) {
        border-bottom: none;
        padding-top: 6px;
        padding-bottom: 15px;
      }
    }
  }
}