.typeahead-wrapper {
  .v-popover {
    .trigger {
      width: 100%;
    }
  }

  .input-group-addon {
    position: absolute;
    z-index: 1;
    width: 40px;
    top: 0;
    background-color: transparent;
    border: none;
    margin-top: -1px;
    text-align: center;

    .fa {
      margin-top: 11px;
    }
  }

  .form-control {
    border-top-left-radius: $border-radius !important;
    border-bottom-left-radius: $border-radius !important;
    padding-left: 40px;
  }

  .form-control.is-open {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px;
  }

  .form-control-lg {
    height: calc(1.5em + 1rem + 2px);
    // padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
  }

  // Overrides for typeahead in the filters
  &.typeahead-in-filter-header {
    margin-top: 3px;

    .input-group-addon {
      bottom: -1px;
      top: auto;
      width: 20px;
      height: 24px;
      padding-top: 0;
      margin-top: -2px;
      border-radius: 12px;

      .fa {
        margin-top: 0;
      }
    }

    .form-control {
      padding-left: 25px;
      height: 24px;
      margin-top: -4px;
      border-radius: 12px !important;
      &:focus {
        outline: none;
        box-shadow: none;
        border-color: $blue;
      }
    }
  }
}

.list-group-typeahead {
  max-height: 230px;
  border-radius: $border-radius;
  border-bottom: $border-base-1;
  background-color: $white;

  .typeahead-item, .typeahead-item-add {
    padding: 10px 15px;

    &:first-of-type {
      border-top: $border-white-1;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }

    &:hover {
      cursor: pointer;
    }
  }
  .typeahead-item.isActive {
    background: $white-dark
  }
}

.typeahead-inner {
  max-width: 350px;
  overflow-y: visible;
  overflow-x: hidden;
}