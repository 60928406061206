// default gradient header
.app-header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 3;
  height: $header-height;
  display: flex;
  align-items: center;

  .back-btn {
    white-space: nowrap;
    &:hover {
      opacity:0.5;
    }
  }

  .warning-test {
    width: 100%;
    height: 3px;
    background: $red;
    position: fixed;
    top: 0;
  }

  .navbar {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .navbar-brand {
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: $white;
    font-weight: 700;
    font-size: 1.1em;
    padding: 0px 15px 0px 0px;
    margin-right: 0;
    position: relative;
    display: flex;
    align-items: center;

    .al-logo {
      // position: absolute;
      // top: 3px;
      width: 18px;
      height: 18px;
      transform: rotate(90deg);
    }

    @include respondToWidth(t) {
      //padding: 15px 15px;
    }
  }

  .navbar-container {
    padding-right: 0px;

    .nav {
      color: $white;

      .nav-item {
        height: calc(#{$header-height} - 21px);
        padding: 0px 12px;

        .tooltip-inner {
          max-height: unset;
        }

        .dropdown-menu-custom {
          top: 48px;
          right: 0px;
          padding: 0.5rem !important;
          max-width: 350px;

          .inner-list {
            max-height: calc(100vh - 240px);
          }

          .half-inner-list {
            max-height: calc(50vh - 120px);
          }

          .inner-list, .half-inner-list {
            overflow-y: scroll;
            margin-right: -5px;

            &::-webkit-scrollbar {
              width: 12px;
            }

            &::-webkit-scrollbar-track {
              -webkit-box-shadow: none
            }

            &::-webkit-scrollbar-thumb {
              border: 4px #fff solid;
              border-radius: 12px;
              background-color: #d8d8d8;
            }
          }

          .dropdown-item {
            color: $text-primary;
            border-radius: $border-radius;
            padding: 0.25rem 0.5rem;
            max-width: 100%;
            min-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;

            @include showOnHover();

            &:hover, &:active {
              cursor: default;
              background: $white;
            }
          }

          a.dropdown-item {
            &:hover {
              cursor: pointer;
              background: $blue-transparent;
            }
          }

          a.dropdown-item.active {
            cursor: pointer;
            background: $white;
            color: $text-primary;

            &:hover, &:active {
              cursor: pointer;
              background: $blue-transparent;
            }
          }

          $nav-item-height: 49px;
          $wrapper-height: 350px;
          $wrapper-width: 350px;

          @include m('notification') {
            @extend .dropdown-menu-custom;
            right: 6px !important;
            max-width: $wrapper-width;
            width: $wrapper-width;

            .infinite-status-prompt {
              padding-bottom: 0px;
            }

            .wrapper-outer {
              overflow: hidden;
              max-height: $wrapper-height;
              position: relative;
              display: flex;
              flex-direction: column;
            }

            ::-webkit-scrollbar {
              width: 12px;
            }

            ::-webkit-scrollbar-track {
              -webkit-box-shadow: none
            }

            ::-webkit-scrollbar-thumb {
              border: 4px #fff solid;
              border-radius: 12px;
              background-color: #d8d8d8;
            }

            .wrapper-inner {
              overflow-y: scroll;
              overflow-x: hidden;
            }

            .dropdown-items {
              direction: ltr;
            }

            .dropdown-item {
              max-width: calc(450px - 1rem);
              height: $nav-item-height;
              padding: 0rem !important;
              overflow: hidden;
              margin-bottom: 0.5rem;
              display: flex;

              &:hover {
                cursor: pointer;

                .dropdown-item--right {
                  background: $white-dark;
                }
              }

              @include m('left') {
                width: $nav-item-height;
                margin-right: 0.5rem;

                .pp_missing {
                  background: $grey-lightest;
                  width: $nav-item-height;
                  height: $nav-item-height;
                  line-height: $nav-item-height;
                }

                .pp_avatar {
                  width: $nav-item-height;
                  height: $nav-item-height;
                  margin-top: 0px;
                }
              }

              @include m('right') {
                flex-grow: 1;
                height: $nav-item-height;
                padding: 0.5rem 0.5rem;
                font-size: 0.85em;
                line-height: 18px;
                border-radius: $border-radius;

                .message {
                  display: block;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }
            }
          }
        }

        .arrow-top-right:before, .arrow-top-right:after {
          top: -17px;
        }
      }

      .nav-link {
        height: calc(#{$header-height} - 21px);
        line-height: calc(#{$header-height} - 21px);
        text-align: center;
        padding: 0;

        color: $white !important;

        svg {
          fill: $white !important;
        }

        &:hover, &:active, &:focus {
          color: $black-transparent-3 !important;
          svg {
            fill: $black-transparent-3 !important;
          }
        }
      }

      .nav-link-button {
        color: $white !important;

        background-color: transparent;
        border: 1px solid $white;
        border-radius: $border-radius;
        padding: 6px 10px;
        // height: auto;
        height: calc(#{$header-height} - 21px);
        line-height: calc(#{$header-height} - 21px);

        transition: 0.2s;

        &:hover {
          color: $blue !important;
          background-color: $white;
          border-radius: $border-radius;
        }
        &:active, &:focus {
          color: $white !important;
          background: rgba(255,255,255,0.2)
        }
      }

      .nav-link-icon {
        margin-top: 0px;
        line-height: calc(#{$header-height} - 21px);
      }

      .nav-item.bd-l, .nav-item.bd-r {
        border-color: $black-transparent;
      }

      .avatar-header {
        .pp_avatar {
          width: 31px;
          height: 31px;
          border-radius: $border-radius;
          margin-top: 1px;
        }

        .pp_missing {
          width: 31px;
          height: 31px;
          margin-top: 1px;
          line-height: 31px;
          font-size: 1em;
          color: $white;
          font-weight: 500;
          background: $black-transparent;
          border-radius: $border-radius;
        }
      }
    }

    .dropdown-research_group, .dropdown-community {
      width: 350px;
    }

    .dropdown-create {
      width: 350px;
      .dropdown-item {
        display: block;
        border-radius: $border-radius;
        text-align: left;
        white-space: normal;

        &_icon {
          width: 27px;
          margin: 0 !important;

          i {
            display: block;
          }

          .with_offset {
            padding-top: 3px;
          }
        }

        &_text {
          width: calc(100% - 27px);
          max-width: calc(100% - 27px);
          line-height: 20px;

          &.with_offset {
            margin-left: 27px;
          }
        }

        &_text, &_icon {
          display: block;
        }
      }
    }
  }
}