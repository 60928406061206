.table {
  color: $text-regular;

  thead th {
    border-bottom: none;
  }

  th {
    border-top: $border-base-1;
  }

  td {
    border-top: $border-base-1;
  }

  .select-label {
    &:after {
      top: 19px;
    }
  }

  .td-260px {
    width: 260px;
  }

  .td-50px {
    width: 50px;
  }
}

.table-padding-x-0 {
  th, td {
    &:first-of-type {
      padding-left: 0px;
    }

    &:last-of-type {
      padding-right: 0px;
    }
  }
}

.table-researcher-col {
  td, th {
    // vertical-align: middle;
    line-height: 60px;
    padding: 0 0.75rem 0 0 !important;

    &:last-of-type {
      padding: 0 !important;
    }
  }
}

.table-researcher {
  th {
    border-top: none;
    font-weight: 500;
  }

  .row {
    margin: 0px;
  }

  .avatar {
    width: 40px;
    padding-right: 0px;
  }

  .name {
    width: 200px;
  }

  .content {
    width: 250px;
    max-width: 250px;
  }

  .content--large {
    width: 40%;
    max-width: 40%;
  }

  .role-editable {
    width: 70px;
  }

  td {
   vertical-align: middle;
  }

  .is-edit-pencil--right {
    top: 22px;
    right: 15px;
  }

  .dropdown-menu-custom--bottom {
    right: 0px !important;
    top: 55px !important;
  }

  tbody:only-child {
    tr:first-of-type > td {
      border: none;
    }
  }
}

.table-striped tbody tr:nth-of-type(odd) {
  background: $white-dark;

  .pp_missing {
    background: $white;
  }
}
