// Range Slider
$range-width: 50% !default;
$range-handle-size: 14px !default;
$range-track-height: 16px !default;

.range-slider {
  width: $range-width;
  margin-left: 25%;
}

.range-slider-left {
  width: 30%;
  margin-left: 25px;
}

.range-slider__range {
  appearance: none;
  height: $range-track-height;
  border-radius: 10px;
  background: $grey-lightest;
  border: 0;
  outline: 0;
  padding: 0;
  margin: 0;
  opacity: 0.75;
  transition: opacity .2s;

  // Range Handle
  &::-webkit-slider-thumb {
    cursor: pointer;
    appearance: none;
    border: 0;
    width: $range-handle-size;
    height: $range-handle-size;
    border-radius: 50%;
    background: $blue;
    transition: background .15s ease-in-out;
  }

  &::-moz-range-thumb {
    cursor: pointer;
    appearance: none;
    width: $range-handle-size;
    height: $range-handle-size;
    border: 0;
    border-radius: 50%;
    background: $blue;
    transition: background .15s ease-in-out;
  }
}

.range-slider__range:hover {
  opacity: 1;
}
