.category-overview {
  .category-overview--card {

    cursor: pointer;
    transition: box-shadow 0.1s;
    padding: 1.5rem;
    width: 100%;
    min-width: 100%;

    .category-icon-wrapper {
      line-height: 0.9em;
      font-size: 0.9em;
      text-align: center;
      width: 20px;
    }

    @include respondToWidth(m) {
      &.query-active {
        padding-top: 12px;
        padding-bottom: 12px;
        h2 {
          margin-bottom: 0;
        }

        .category-extra-info {
          display: none;
        }
      }
      .category-title {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-wrap: nowrap;
      }
    }

    &.is-disabled {
      cursor: initial;
      pointer-events: none;
      background-color: rgba(255,255,255,0.5);
    }

    &:hover {
      h2 {
        color: $blue;
      }
      box-shadow: $box-shadow-hover;
    }
  }
}

.category-list {
  justify-content: center;
  z-index: 2;
  position: sticky;
  top: -16px;
  // Small hack to make sure the result-cards the scroll behind the category list don't have a visible border
  margin-left: -3px;
  margin-right: -3px;

  .category-list-card {
    word-wrap: break-word;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: $white;
    border-radius: 4px;
    flex-basis: 0;
    margin-right: 5px;
    max-width: 144px;
    transition: all 0.2s;

    .category-list-name {
      line-height: 1.1em;
      color: $grey;
    }

    .category-list-icon {
      font-size: 32px;
      height: 32px;
      width: 32px;
      text-align: center;
    }

    .category-list-icon, .category-list-count {
      color: $text-secondary;
    }

    &:hover:not(.no-results):not(.active) {
      box-shadow: $box-shadow-hover;
      cursor: pointer;
      color: $blue;

      .category-list-icon, .category-list-count, .category-list-name {
        color: $blue;
      }
    }

    &.active {
      background-color: $blue;
      color: $white;

      .category-list-icon, .category-list-count, .category-list-name {
        color: $white;
      }
    }

    &.no-results:not(.active) {
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;

      .category-list-icon {
        opacity: 0.5;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}