.pp_missing, .pp_avatar {
  font-size: 2em;
  background-size: contain !important;

  &.is-square {
    border-radius: $border-radius;
  }

  &_edit {
    position: absolute;
    top: -4px;
    right: -4px;
    width: 28px;
    height: 28px;
    line-height: 18px;
    text-align: center;
    border-radius: 100%;
    background: $blue;
    border: $border-base-1;

    i {
      font-size: 0.45em;
      line-height: 20px;
    }
  }
}

.pp_missing {
  position: relative;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  line-height: 75px;
  background: $grey-light;
  float: left;
  color: $white;
  text-align: center;
}

.pp_avatar {
  position: relative;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  float: left;

  &_edit {
    background: $white;
    color: $blue;
  }
}

.profile-dd {
  .pp_missing, .pp_avatar {
    width: 40px;
    height: 40px;
    overflow: hidden;
    background: $grey-lightest;
    color: $white;
    border-radius: $border-radius;
    display: block;
    float: none;
  }

  .pp_missing {
    border: $border-base-1;
    font-size: 1.2em;
    width: 41px;
    height: 41px;
    line-height: 40px;
  }
}

.table, .question, .question_avatar, .answer, .comment {
  .pp_missing, .pp_avatar {
    width: 40px;
    height: 40px;
    overflow: hidden;
    background: $grey-lightest;
    color: $white;
    border-radius: $border-radius;
  }

  .pp_missing {
    border: $border-base-1;
    font-size: 1.2em;
    width: 41px;
    height: 41px;
    line-height: 40px;
  }
}
