.settings {
  .fixed-to-top {
    position: sticky !important;
    top: calc(#{$header-height} + 30px);
    transition: top 0.2s ease-in-out;
    z-index: 1;
  }
  
  .is-hover {
    &:hover {
      color: $blue !important;

      i {
        color: $blue !important;
      }
    }
  }
  
  h2.active {
    color: $blue !important;
  }

  .nav-settings {
    li {
      padding-left: 0.5em;
    }

    li a {
      color: $text-regular;
      position: relative;
    }

    .active {
      color: $blue !important;

      &:before {
        content: '';
        position: absolute;
        width: 4px;
        height: 16px;
        top: 2px;
        left: -10px;
        background: $blue;
      }
    }
  }
}