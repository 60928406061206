@font-face {
  font-family: 'icomoon';
  src:  url('al-custom-icons/fonts/icomoon.eot?f54e6i');
  src:  url('al-custom-icons/fonts/icomoon.eot?f54e6i#iefix') format('embedded-opentype'),
    url('al-custom-icons/fonts/icomoon.ttf?f54e6i') format('truetype'),
    url('al-custom-icons/fonts/icomoon.woff?f54e6i') format('woff'),
    url('al-custom-icons/fonts/icomoon.svg?f54e6i#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="al-icon-"], [class*=" al-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.al-icon-stars:before {
  content: "\e90c";
}
.al-icon-government:before {
  content: "\e90b";
}
.al-icon-diseases:before {
  content: "\e909";
}
.al-icon-genes:before {
  content: "\e90a";
}
.al-icon-ai:before {
  content: "\e908";
}
.al-icon-investor:before {
  content: "\e907";
}
.al-icon-incubator:before {
  content: "\e904";
}
.al-icon-compare_list:before {
  content: "\e905";
}
.al-icon-network:before {
  content: "\e906";
}
.al-icon-spinoff:before {
  content: "\e903";
}
.al-icon-funded_project:before {
  content: "\e900";
}
.al-icon-notifications:before {
  content: "\e901";
}
.al-icon-patent:before {
  content: "\e902";
}
