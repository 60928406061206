// Creates a container, with inside a green checkmark.
// When hovering over the container, the checkmark turns into a red cross
.check-remove-wrapper {
  .fa-check-remove {
    font-family: FontAwesome;
    &::before {
      content: "\f00c";
      color: $green;
    }
  }

  &:hover {
    .fa-check-remove::before {
      content: "\f00d";
      color: $red;
    }
  }
}
