.btn-category-dropdown {
  color: $white;
  background-color: $blue;
  white-space: nowrap;
  min-height: 43px;

  &:hover, &:focus {
    color: $white;
    background-color: $blue-dark;
    box-shadow: none;
  }

  &.has-focus {
    box-shadow: 0 0 0 3px $blue-dark;
  }
}

.btn-keyword {
  border-radius: 160px;
  background: white;
  padding: 1px 10px;
  margin-right: 4px;
  margin-bottom: 4px;
  color: $text-primary;
  font-size: 0.9em;
  border: $border-base-1;
}

a.btn-keyword {
  &:hover {
    border-color: $blue;
    color: $blue;
  }

  &:active {
    border-color: $blue;
    color: $white;
    background-color: $blue;
  }
}

.search {
  &_bar {
    &_input {
      border: none;
      position: relative;

      &--synonyms-tooltip {
        z-index: 1;
        position: absolute;
        width: 100%;
        background-color: $white;

        .synonyms-title {
          padding: 0.5rem 0.75rem 1rem;
          display: block;
        }

        .synonyms-lists {
          max-height: calc(100vh - 200px);
          overflow: auto;
          padding: 0 0.75rem;

          ul {
            padding: 0;
            &:last-of-type {
              margin-bottom: 0;
            }
            li {
              list-style: none;
              display: inline;
            }
          }
        }
      }

      &--inner-button {
        white-space: nowrap;
        padding-top: 0.5rem;
        cursor: pointer;

        &.fa {
          padding-top: 0.7rem;
        }

        &:hover {
          color: $blue;
        }
      }

      .input__field {
        position: relative;
        width: 100%;
        display: block;
        // border: none;
        // border-top-right-radius: 0;
        // border-bottom-right-radius: 0;
        background: transparent;
        -webkit-appearance: none;

        &:focus {
          outline: none;
          box-shadow: none;
        }

        &::-ms-clear {
          display: none;
        }
      }

      .input__label {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 0;
        margin: 0;
        width: 100%;
        height: 100%;
        text-align: left;
        pointer-events: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &:before, &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: calc(100% - 1px);
        }

        &:after {
          border-bottom: 2px solid $blue-green;
          -webkit-transform: translate3d(-100%, 0, 0);
          transform: translate3d(-100%, 0, 0);
          -webkit-transition: -webkit-transform 0.3s;
          transition: transform 0.3s;
        }
      }

      .input__field:focus + .input__label::after, .input__field.keep-focus + .input__label::after {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }

      .hide-text {
        color: $white-dark;
      }

      .form-control {
        padding: 0.5rem 0.75rem;
        height: 43px;
      }

      &:focus {
        outline: none;
        box-shadow: none;
        background: transparent;
        border: none;
      }
    }
  }

  &_bar, &_bar_header {
    text-align: center;

    &_clear {
      font-size: 3em;
      background: none;
      border: none;
      margin-top: 7.5px;
      color: $text-placeholder;

      @include respondToWidth(t) {
        display: none;
      }

      &:hover {
        cursor: pointer;
        color: $red;
      }

      &:focus {
        outline: 0;
        color: $red;
      }
    }

    &_form.is-invalid {
      color: $red;

      .search_bar_clear, .search_bar_input {
        color: $red;
      }
    }
  }

  &_save {
    @include respondToWidth(t) {
      display: none;
    }

    .btn-save-search {
      background: $blue;
      min-height: 43px;
      font-size: 1em;
      color: $white;
      transition: background-color 0.2s ease;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;

      &:disabled, &.disabled {
        cursor: initial;
        pointer-events: initial;
      }

      &:hover, &:focus {
        background-color: $blue-dark;
      }
    }
  }

  &-button-header-color {
    background: $blue;
    border: none;
    border-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 43px;
    white-space: nowrap;

    color: $white;

    &:hover, &:focus {
      color: $white;
      background: $blue-dark;
    }
  }

  &-toggle-view-container {
    @include respondToWidth(t) {
      display: none !important;
    }

    & > :nth-child(n):not(:last-child) {
      border-right: 1px solid $grey-lightest !important;
    }
  }

  &-toggle-view {
    border: none;
    border-radius: 0;
    font-size: 1.2em !important;
    // height: 43px;
    color: $grey-lighter;

    padding-top: 10px;

    &:hover {
      cursor: pointer;
      color: $blue;
      box-shadow: none;
    }
  }

  &-toggle-view.is-active-view {
    color: $blue!important;

    &:hover {
      cursor: initial;
    }
  }

  &_sort {
    float: right;

    &_select {
      border-radius: $border-radius;
      padding: 0px 10px;
      color: $text-regular;
      min-width: 200px;
      font-size: 0.9em;
      background: $white;
      background-image: linear-gradient(-180deg, $white 0%, $white-dark 90%);
    }
  }

  &_index {
    margin: 0px 1rem;
    width: calc(100% - 250px - 2rem);
    padding: 0px;
    float: left;

    @include respondToWidth(t) {
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-top: 15px !important;
      width: 100%;
    }

    @include m('small') {
      margin: 0px 1rem 0 0;
      width: calc(100% - 100px - 1rem);

      @include respondToWidth(t) {
        width: 100%;
      }
    }

    .btn {
      height: 40px;
      max-height: 40px !important;
      border: 1px solid $blue-green !important;
    }

    .dropdown-item {
      border-radius: $border-radius;
      padding: 0.25rem 0.75rem;
      color: $text-regular;
      padding-left: 30px;
      max-height: 35px !important;

      i {
        padding-right: 2px;
      }

      &.active {
        padding-left: 8px;
        background: $white;
        color: $text-regular;
      }

      &:first-of-type {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }
  }

  &_selected-filters {
    .list-item-search {
      transition: transform .3s, box-shadow .3s;
      padding: 2px 10px;
      border-radius: $border-radius;
      background: $blue;
      margin: 0px;
      display: inline-block;
      margin-bottom: 5px;
      color: $white;

      .filter-count {
        opacity: 0.7;
      }

      i {
        transition: opacity .3s;
        opacity: 0.7;

        &:hover {
          opacity: 1;
        }
      }

      &:hover {
        cursor: pointer;
        background-color: $blue-dark;
      }

      &.single-filter:hover > i {
        opacity: 1;
      }
    }
  }

  &_more-options {
    position: absolute;
    right: 0;
    top: 0;
  }

  &_filters-mobile-wrapper {
    display: block;
    position: sticky;
    top: 154px;
  }

  &_filters-mobile {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: white;

    &-sections:not(:last-of-type) {
      padding-bottom: 16px;
    }
  }

  &_filters {
    position: relative;
    z-index: 0;

    &-sections:not(:last-of-type) {
      padding-bottom: 16px;
    }

    .card {
      position: fixed;
      transition: none;
      box-shadow: none;

      min-height: 100px;
      background: transparent;
      z-index: 2;

      .card-header, .card-body {
        background: transparent;
      }
    }

    h2 {
      margin: 0;
      font-size: 1em;
      line-height: 1.6em;
    }

    h3 {
      font-size: 1em;
    }
  }

  &_filters-mobile, &_filters {

    .scrollable {
      overflow-x: hidden;
      overflow-y: auto;
      transition: none;

      scrollbar-width: thin; // Firefox scrollbar styling
    }

    .checkbox {
      margin: 0px;
      height: 30px;

      &:hover {
        background: $blue-lightest;
      }
    }

    .since-select {
      .ml-5p {
        margin-left: 5%;
      }

      .dropdown-menu-custom {
        padding: 8px 0;
        top: 34px;
        width: 100%;
        right: 0;
        max-height: 215px;
        overflow-y: scroll;
        text-align: left;
        position: absolute;
        z-index: 1;
        background: $white;
        border: 1px solid $border-base;
        border-radius: $border-radius;

        .dropdown-item {
          cursor: pointer;
        }
      }

      ::-webkit-scrollbar {
        width: 18px;
      }

      ::-webkit-scrollbar-track {
        box-shadow: none;
        -webkit-box-shadow: none;
      }

      ::-webkit-scrollbar-thumb {
        border: 10px $grey-lightest solid;
        border-radius: 0;
        background-color: #d8d8d8;
      }
    }

    .checkbox_label {
      margin-top: 7px;
    }

    .checkbox_content {
      width: calc(100% - 30px);
      top: 0px;
      line-height: 30px;
      margin-bottom: 0;

      .checkbox_name {
        width: calc(100% - 30px);
        float: left;
        padding-right: 0px;
      }

      .flag-icon {
        float: left;
        margin-top: 8px;
      }
    }

    .checkbox:hover .checkbox_name {
      color: $text-regular !important;
    }

    .checkbox_content--full-width {
      .checkbox_name {
        width: 100%;
      }
    }

    .radiobutton input[type=radio]:checked + .radiobutton_label:after, .radiobutton input.active + .radiobutton_label:after {
      background-color: transparent;
      opacity: 0;
    }

    .radiobutton input[type=radio].active + .radiobutton_label:after, .radiobutton input.active + .radiobutton_label:after {
      top: 12px;
      left: 21px;
      background: $blue !important;
      opacity: 1 !important;
    }

    .radiobutton {
      margin: 0 !important;
      height: 30px;
    }

    .radiobutton_label {
      margin-top: 7px;
    }

    .radiobutton_content {
      width: calc(100% - 30px);
      margin-bottom: 0px;
      line-height: 36px;

      .radiobutton_name {
        width: calc(100% - 40px);
        float: left;
        padding-right: 0px;
      }

      &:hover {
        color: $text-regular !important;
      }
    }
  }

  &_results {
    $card-header-height--small: calc(80px + 1rem);
    $card-header-height: calc(120px);
    $card-body-height: calc(32px + 2rem);
    $card-body-height--big: calc(90px + 2rem);
    // change also css on bookmark-trigger
    $card-footer-height--small: calc(45px + 1rem);
    $card-footer-height: calc(52px + 1rem);
    $card-footer-height--big: calc(63px + 1rem);
    $extra-padding-popover: 250px;

    .category-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @include respondToWidth(t) {
        justify-content: flex-start;
        text-align: right;
      }
    }

    .card {
      border-radius: $border-radius;
      margin-bottom: 1rem;
      border: none;
      height: 100%;
    }

    @include m('preview') {
      .card {
        a {
          color: $blue;

          mark {
            color: $blue;
          }
        }
      }
    }

    .suggestions-count {
      // position: absolute;
      background: $white;
      // left: 100%;
      // top: 2px;
      border: $border-base-1;
      padding: 0px 5px;
      color: $text-secondary;
      border-radius: $border-radius;
      margin-left: 10px;
      font-size: 0.7em;
      line-height: 19px;

      // &--small {
      //   right: -40px;
      // }
    }

    .card-light-overlap {
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }

    .card-wrapper {
      position: relative;
      height: calc(100% - 10px);

      .v-popover, .v-popover .trigger {
        height: 100%;
      }

      .bookmark-trigger {
        .icobutton {
          padding: 5px 10px;
          border-radius: $border-radius;
          margin-right: calc(-1.25rem + 14px);

           i {
             padding-top: 4px;
           }

           &:hover {
            background: $blue-transparent;
          }
        }

        .total-times-added {
          position: absolute;
          right: 30px;
        }

        .arrow-top-right:after, .arrow-top-right:before {
          right: 36px;
        }
      }
    }

    .restore-padding {
      padding-left: 10px;
      padding-right: 10px;

      .list-view {
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    .popover-search-result, .card-block, .card-list {
      &--project {
        .row-content_small {
          .row-content--side {
            font-size: 0.8em;
          }
        }
      }
    }

    .card-list, .card-block, .card-placeholder {
      margin-bottom: 10px;
      transition: box-shadow .3s, transform .3s;

      &:hover {
        cursor: pointer;
        box-shadow: $box-shadow-hover;
        transform: translate3d(0, -1px, 0);
      }
    }

    .card-list, .card-block {
      background: $white;

      .card-header, .card-body, .card-footer, .card-actions {
        padding: 0.5rem 1.25rem !important;
        position: relative;
      }

      .card-actions {
        background: $white-light;
        margin-bottom: 4px;

        a, a:visited {
          color: $blue !important;
        }
        a:hover, a.active {
          color: $white !important;
        }
      }

      .card-header {
        background: transparent;
        border-bottom: none;

        &--title {
          padding-right: 60px;
          font-weight: 500;
          color: $text-primary;
          transition: color .3s;

          mark {
            color: $text-primary;
            transition: color .3s;
          }

          &:visited {
            color: $blue-visited !important;

            mark {
              color: $blue-visited !important;
            }
          }
        }
      }

      .card-body {
        ul {
          padding-left: 0;
          list-style: none;
          margin-bottom: 0px;
          min-height: 20px;
          // width: calc(100% - 30px);
        }
      }

      .card-footer {
        border-top: none;
        background: $white-light;

        .row-content:not(:first-of-type) {
          padding-top: 0.25rem;
        }

        mark {
          color: $text-regular;
        }

        a {
          color: $grey;
          transition: 0.3s;
          &:hover {
            color: $blue !important;
          }
        }
      }

      .flag {
        position: absolute;
        left: -1px;
        top: 4px;
      }

      &:hover {
        cursor: pointer;

        .card-header {
          &--title {
            color: $blue !important;

            a {
              color: $blue !important;
            }

            mark {
              color: $blue !important;
            }
          }
        }
      }
    }

    .card-block {
      .card-header {
        height: $card-header-height;
        min-height: $card-header-height;
      }

      .card-body {
        height: $card-body-height;
        min-height: $card-body-height;

        .flag {
          left: 3px;
        }
      }

      .card-footer {
        height: $card-footer-height;
        min-height: $card-footer-height;
        overflow: hidden;

        &--buttons {
          height: $card-footer-height--small;
          min-height: $card-footer-height--small;
        }
      }

      &--clinical_trial, &--news {
        .card-header {
          height: auto;
          min-height: $card-header-height--small;
        }

        .card-body {
          height: auto;
        }
      }

      &--opportunity {
        .pp_missing, .pp_avatar, .researcher-thumbnail {
          width: 40px !important;
          height: 40px !important;
          line-height: 40px !important;
        }

        .thumbnail-block .name {
          width: calc(100% - 40px) !important;
          padding-left: 1.5rem !important;
        }
      }

      &--researcher {
        .card-header {
          height: 2rem;
          min-height: 2rem;
        }
      }

      &--research_group {
        .card-header {
          height: 3rem;
          min-height: 3rem;
        }
      }

      &--research_topic {
        .card-header {
          height: $card-header-height--small;
          min-height: $card-header-height--small;
        }
      }

      &--publication {
        .card-footer {
          height: $card-footer-height--big;
          min-height: $card-footer-height--big;
        }
      }

      &--organisation {
        .card-header {
          height: 3rem;
          min-height: 3rem;
        }
        .card-footer {
          height: auto;
          min-height: initial;
        }

        .card-body {
          height: auto;
        }
      }
    }

    .card-list {
      .card-body {
        .description {
          line-height: 1.4em;
        }

        ul {
          min-height: 40px;
          padding-top: 7.5px;
        }
      }

      &--clinical_trial {
        .card-body {
          ul {
            min-height: auto;
          }
        }
      }

      &--researcher {
        .thumbnail-block {
          .name {
            padding-top: 6px;
            padding-left: 11px;
          }
        }
      }
    }

    .popover-search-result, .popover-preview-search-result {
      pointer-events: none;
      width: calc(100% - 30px + #{$extra-padding-popover});
      min-width: calc(100% - 30px + #{$extra-padding-popover});
      max-width: calc(100% - 30px + #{$extra-padding-popover});

      .popover-inner {
        width: calc(100%);
        min-width: calc(100%);
        max-width: calc(100%);
      }

      mark {
        background-color: transparent;
        color: $text-yellow;
      }

      .row-content {
        &--side {
          color: $white !important;
        }
      }

      // fix for margin top
      ul li:first-of-type {
        margin-top: 0 !important;
      }
    }

    .disabled {
      pointer-events: none;
    }

    .v-popover .trigger {
      width: 100%;
      display: block !important;
    }

    .bg-highlight {
      .card-header, .card-body, .card-footer {
        background: $yellow-light !important;
      }
    }
  }

  .popover-preview-search-result {
    min-width: 300px !important;
    max-width: 500px !important;
  }

  &_table-faq {
    .td-20 {
        width: 20%;
      }
    .td-40 {
      width: 40%;
    }

    .td-60 {
      width: 60%;
    }
  }

  .card-more-results {
    .hr-close {
      margin: 4px auto;
      width: calc(100% + 2.4rem);
      height: 3px;
      margin-left: -1.2rem;

      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      border-bottom: $border-base-1;
      border-left: $border-base-1;
      border-right: $border-base-1;

      &:last-of-type {
        margin-bottom: 6px;
      }
    }

    a.btn:visited, a.btn {
      color: $white !important;
    }
  }

  .search_bar--row {
    z-index: 2;
    background: $white-dark;
    padding-bottom: 5px;
    // Small hack to make sure the result-cards the scroll behind the category list don't have a visible border
    margin-right: -3px;
    padding-right: 3px;
    margin-left: -3px;
    padding-left: 3px;
  }
}

.search_bar--row {
  &:focus-within, &:hover {
    .search_bar_tips {
      opacity: 1;
      &:hover {
        color: $blue-dark!important;
      }
    }
  }

  .search_bar_tips {
    position: absolute;
    left: 0;
    top: -20px;
    opacity: 0;

    @include respondToWidth(m) {
      top: -68px;

      &.is-minimal {
        top: -20px;
      }
    }


    &:hover {
      opacity: 1;
      color: $blue-dark!important;
    }
  }

  .search_tips_expanded {
    position: absolute;
    left: 0;
    top: 42px;
    width: 100%;
    background-color: $white;
    border: 1px solid $blue!important;
    border-bottom-left-radius: 4px!important;
    border-bottom-right-radius: 4px!important;
    padding: 0.5rem 0.75rem;
    z-index: 3;

    mark {
      background-color: transparent;
      font-weight: 700;
      color: $blue-dark;
    }

    @include respondToWidth(m) {
      table > tr > td {
        width: 100%;
        display: inline-block;
      }
    }
  }
}

.filter-header-color {
  background: $white;
}


.bg-grey.fixed-to-top {
  padding-top: 100px !important;
}

.modal-search-content {
  position: relative;
  height: 100%;

  h2 {
    padding-right: 20px;
  }

  a:visited {
    color: $blue-visited !important;

    mark {
      color: $blue-visited !important;
    }
  }

  .dropdown-menu-custom--left {
    right: auto;
    top: 5px;
    left: 90px;
    min-width: 300px;
  }

  .bookmark-trigger {
    width: 150px;

    @include respondToWidth(m) {
      width: auto;
    }

    .total-times-added {
      position: absolute;
      top: 6px;
      left: -10px;
    }

    .icobutton {
      width: 35px;
      height: 40px;
      display: block;
      padding: 5px 10px;
      border-radius: $border-radius;

      i {
        padding-top: 4px;
      }

      &:hover {
        background: $blue-transparent;
      }
    }

    .dropdown-menu-custom {
      top: 35px;
    }

    .arrow-top-right:after, .arrow-top-right:before {
      top: -16px;
      right: 39px;
    }
  }
}

.search_bar_form {
  .search_bar_input>* {
    border: 1px solid transparent;
    // border-left: none;
    // border-right: none;
    // border-radius: 0;

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      border-left: 1px solid transparent;
      border-right: none;
    }
    &:not(:first-child) {
      border-left: none;
      border-right: none;
      border-radius: 0;
    }
    &:last-child {
      border-left: none;
      border-right: none;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .search_bar_input--inner-button {
    background-color: $white;

    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  }

  &:focus-within, &:hover, &.show-border {
    .input__field, .search-button-header-color, .search_bar_input--inner-button {
      border-color: $blue;
    }
  }
}

.card-body-graph {
  height: auto!important;

  .compare-chart-legend {
    padding-right: 5px;

    .bd-legend-primary, .bd-legend-secondary {
      float: left;

      &--soft {
        line-height: 22px;
        padding-top: 2px;
        margin-left: 1px;
        float: right;
      }
    }

    .bd-legend-primary {
      border-bottom: 5px solid rgba(67,174,197,0.7);
      &--soft {
        border-bottom: 5px solid #e7f2f4;
      }
    }

    .bd-legend-secondary {
      border-bottom: 5px solid $pink;
      &--soft {
        border-bottom: 5px solid rgba(239, 169, 187, 0.2) !important;
      }
    }
  }

  .small-chart {
    position: relative;
    margin-left: -24px;
    margin-right: -20px;
    margin-top: -7.5px;
  }

  .ratio-box {
    margin-left: -27px;
    margin-right: -20px;
    margin-top: -26px;
    width: auto;

    @include respondToWidth(t) {
      margin-bottom: -26px;
    }
  }
}