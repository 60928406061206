.bg-grey, .bg-modal-grey, .bg-grey-hover:hover {
  background: $white-dark;
}

.bg-grey-gradient {
  background: #dfe2e6; /* Old browsers */
  background: -moz-linear-gradient(right, $white 0%, #dfe2e6 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(right, $white 0%, #dfe2e6 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to left, $white 0%, #dfe2e6 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#dfe2e6',GradientType=1 ); /* IE6-9 */
}

.bg-blue-green-gradient {
  background: $blue-light; /* Old browsers */
  background: -moz-linear-gradient(right, $blue 0%, $green 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(right, $blue 0%, $green 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, $blue 0%, $green 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#42AEC6', endColorstr='#37BCAE',GradientType=1 ); /* IE6-9 */
}

.bg-blue-gradient-bottom {
  background: $white; /* Old browsers */
  background: -moz-linear-gradient(bottom, $white 0%, $blue-lightest 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(bottom, $white 0%, $blue-lightest 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, $white 0%, $blue-lightest 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='ffffff', endColorstr='#ecf3fc',GradientType=1 ); /* IE6-9 */
}

.bg-transparent {
  background: transparent;
}

.bg-research_group {
  //background: url('../img/bg-research_group.svg') no-repeat center center fixed;
  background: url('../img/bg-research_group.svg') center;
  background-size: cover;
}

.bg-blue {
  background: $blue !important;
}

.bg-highlight {
  background: $yellow-light;
}

.bg-highlight-dark {
  background: #FDE18C;
}

.bg-white {
  background: $white;
}

.bg-white-light {
  background: $white-light;
}

.bg-yellow {
  background: $yellow;
}

.bg-yellow-light {
  background: #FCF9E7;
}

.bg-green {
  background: $green !important;
}

.bg-green-light {
  background: $green-light !important;
}

.bg-blue-green {
  background: $blue-green;
}

.bg-red {
  background: $red !important;
}

.bg-red-light {
  background: $red-light!important;
}

.bg-orcid {
  background: #9cc732;
}

.bg-linkedin {
  background: #0077b5;
}

.bg-transparent-blue {
  background: $blue-transparent;
}

.bg-grey-light {
  background: $grey-lightest;
}

.bg-grey-lighter {
  background: $grey-bg-light;

  &.btn:hover {
    background: $grey-lightest;
  }
}

.bg-grey-semi-light {
  background: $grey-semi-light;
}

.bg-filter-grey {
  background: #ecf1f3;
}

.bg-full-page {
  width: 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 110%;
    left: -55px;
    top: -10%;
    height: 450px;
    background: url('../img/bg-research_group.svg') center;
    background-size: cover;
    @include transform(rotate(-8deg));
  }
}
