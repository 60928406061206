.photo-cropper {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 230px;
  height: 230px;

  &__thirds {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    overflow: hidden;
    pointer-events: none;
    border: 2px solid #fff;

    &-line {
      position: absolute;
      z-index: 1;
      background: rgba(255,255,255,.55);

      &--top-horizontal {
        width: 100%;
        height: 1px;
        top: 33.33333%;
      }

      &--bottom-horizontal {
        width: 100%;
        height: 1px;
        top: 66.66666%;
      }

      &--left-vertical {
        height: 100%;
        width: 1px;
        left: 33.33333%;
      }

      &--right-vertical {
        height: 100%;
        width: 1px;
        left: 66.66666%;
      }
    }
  }
}

.cursor-grab {
  cursor: move;
  cursor: grab;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
.cursor-grabbing {
  cursor: grabbing;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}
