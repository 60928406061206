.tooltip {
  display: block !important;

  .tooltip-inner {
    color: $white;
    border-radius: $border-radius;
    padding: 5px 10px 4px;
    max-width: 400px;
    width: auto;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: $grey;
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="toptable"] {
    margin-top: 0px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: 15px;
      left: calc(50% + 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &.popover {
    $color: $white;

    .popover-inner {
      text-align: left;
      padding: 0.5rem 0.75rem;
      background: $grey;
      color: $white;
      border-radius: $border-radius;
      box-shadow: $box-shadow-bottom-right;
    }

    .popover-arrow {
      border-color: $grey;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0s, visibility .05s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}

.popover {
  border: none;
  background: transparent;
}

.tooltip.popover, .tooltip {
  .tooltip-inner {
    background: $grey-transparent;
    font-family: $font;
  }
}

.tooltip.popover .popover-arrow, .tooltip .tooltip-arrow {
  border-color: $grey-transparent;
}

.narrow-tooltip {
  max-width: 250px;

  // .tooltip-inner {
  //   text-align: left;
  // }
}