.researcher {
  $avatar-height: 80px;

  .profile_header {
    .researcher-picture {
      height: $avatar-height !important;
      width: $avatar-height !important;

      .pp_missing {
        background: $grey-lighter;
      }

      .pp_missing, .pp_avatar {
        height: $avatar-height !important;
        width: $avatar-height !important;
        line-height: $avatar-height !important;
        box-shadow: $box-shadow-bottom;
      }
    }
  }

  .secondary-emails {
    .radiobutton:last-of-type .note {
      margin-bottom: 0px;
    }
  }
}

.bookmark-folder {
  @include showOnHover();

  &--actions {
    white-space: nowrap;
    > * {
      display: inline-block;
    }
  }

  .info-header {
    width: 100%;
    justify-content: space-between;
    display: flex;
    word-break: break-word;

    @include respondToWidth(t) {
      display: block;
    }

    &__bookmark-name {
      width: calc(100% - 200px);
      display: flex;
      @include respondToWidth(t) {
        width: 100%;
      }
    }

    .actions {
      margin-top: -0.25rem;
    }
  }

  .action-item {
    width: 50px;
  }

  .dropdown-menu-custom {
    top: 40px;
  }

  .arrow-top-right:before, .arrow-top-right:after {
    top: -17px;
  }
}

.modal-profile-picture {
  max-width: 750px !important;

  .upload-profile-picture {
    &_wrapper {
      position: relative;
      height: 232px;
      background: #060707;
    }

    .v-spinner {
      width: 50px;
    }
  }

  .photo-cropper__helper-text {
    background: #060707;
  }
}

.publication_checkbox-connect, .member_checkbox-import, .research_group_checkbox-import {
  width: 30px;
  height: auto;
  margin-left: 0px;

  .checkbox_label {
    position: absolute;
    top: 0px;
    left: 0px;
  }

  input[type=checkbox]:checked + .checkbox_label:after {
    top: 4px;
    left: 3px;
  }
}

.member_checkbox-select-all {
  width: 30px;
  height: auto;
  margin-left: 0px;

  .checkbox_label {
    position: absolute;
    top: 6px;
    left: 0px;
  }

  input[type=checkbox]:checked + .checkbox_label:after {
    top: 4px;
    left: 3px;
  }
}

.publication {
  position: relative;
  &_card {
    width: 95%;
    float: left;
  }

  &_card-editable {
    width: 5%;
    text-align: right;
    float: right;
  }

  &_modal-editable {
    position: absolute;
    right: -3rem;
    top: 3rem;
    margin-top: 0px !important;
  }

  &_content-connect {
    width: calc(100% - 30px);
  }
}

.change-account {
  .btn-bottom {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    width: calc(100% - 2rem);
  }

  .info-text {
    margin-bottom: 70px;
  }


  .card-header {
    background: $white;
  }
}

.message-centered-verified {
  margin-top: 90px;
}

.change-account, .researcher {
  .warning-non-verified--large {
    width: 100%;
  }

  .warning-non-verified {
    width: 180px;

    img {
      margin-top: 6px;
    }
  }
}

.avatars {
  margin-left: 16px;

  .avatar .pp_avatar, .avatar .pp_missing {
    height: 50px;
    width: 50px;
    border: $border-white-2;
    box-shadow: $box-shadow-bottom-right;
  }

  .avatar .pp_missing {
    font-size: 1em;
    line-height: 45px;
    background: $grey-lighter;
  }

  .avatar {
    margin-left: -16px;
    z-index: 2;
  }
}
