.horizontal-tab-navigation {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  position: relative;
  margin: 0 auto;

  ul.tab-navigation {
    height: 100%;
    position: relative;
    transform: translateX(0);
    transition: 250ms;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    display: flex;
    align-items: stretch;
    white-space: nowrap;
    overflow-x: hidden;
    padding-left: 0;

    &.w-padding {
      li:first-of-type {
        padding-left: 35px;
      }
      li:last-of-type {
        padding-right: 35px;
      }
    }

    li {
      list-style: none;
      position: relative;
      display: flex;
      align-items: stretch;
      padding: 0;

      a {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        background-color: rgba(174, 197, 204, 0.3);
        padding: 0 30px;
        color: $grey;
        text-decoration: none;
        line-height: 44px;

        &:hover {
          background-color: $white-dark;
        }
        &.active {
          background-color: $white-dark;
          .active-tab {
            transition: 0.3s ease-in-out;
            background-color: $blue;
            height: 2px;
            width: 100%;
            display: inline-block;
            position: absolute;
            bottom: -15px;
            left: 0;
          }
        }
      }

      &.inverted {
        a:not(.active) {
          background-color: rgba(255, 255, 255, 0.3);
          color: $white;

          &:hover {
            background-color: $white-dark;
            color: $grey;
          }
        }
      }
    }
  }

  .tab-reverse, .tab-forward {
    height: 100%;
    display: block;
    position: absolute;
    z-index: 1;

    button {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 10px;

      border: 0;
      background-color: white;

      &:focus {
        outline: 0;
      }
      &:disabled i {
        opacity: 0.5;
      }
    }
  }

  .tab-reverse {
    left: 0;
    padding-right: 10px;
  }

  .tab-forward {
    right: 0;
    padding-left: 10px;
  }
}
