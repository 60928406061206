
.scite-badge-al {
  display: flex!important;
  flex-wrap: wrap;

  &>div {
    @include respondToWidth(m) {
      flex-wrap: wrap;
      flex-direction: column;
    }
  }

  i::before {
    box-sizing: content-box;
  }

  // First part of Scite.ai widget (citation types)
  &>div:first-of-type {
    // margin-bottom: 0!important;
    display: flex!important;
    margin-right: 16px;
    margin-bottom: 4px;

    &::before {
      content: 'Citation Types: ';
      margin-right: 8px;
      font-size: 0.9em;
      font-weight: 500;
    }
  }

  // Second part of Scite.ai widget (citation sections)
  &>div:last-of-type {
    margin-bottom: 0!important;
    display: flex!important;

    .scite-section-tally {
      i {
        padding-top: 2px;
        padding-bottom: 2px;
        margin-right: 2px!important;
      }
    }

    &::before {
      content: 'Cited in Sections: ';
      margin-right: 8px;
      font-size: 0.9em;
      font-weight: 500;
    }
  }
}