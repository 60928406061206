.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;

    button {
        padding: 0 15px;
        height: 35px;
        margin: 2.5px;
        border: none;
        border-radius: 4px;

        &.control {
            background-color: $white;
            color: $blue;
            &:disabled {
                color: $grey-light;
                cursor: not-allowed;
                &:hover {
                    box-shadow: none;
                }
            }
        }

        &.page {
            background-color: $white;
            color: $grey;
            font-weight: 400;
            &.active {
                background-color: $blue;
                color: $white;
                font-weight: 600;
            }
        }
        &:focus {
            outline: none;
        }

        &:hover {
            box-shadow: 0px 0px 10px rgba(71, 82, 88, 0.2);
            color: $blue;
        }
    }
    



    .pages-jump {
        margin-top: 30px;
        display: flex;
        align-items: center;
        color: $grey;
        font-weight: 400;

        &__value {
            height: 35px;
            width: 42px;
            text-align: center;
            border-radius: 4px;
            background-color: $white;
            border: none;
            margin: 0 5px 0 10px;

            &:focus, &:hover {
                border: 1px solid $blue;
                outline: none;
            }
        }

        &__submit {
            padding: 0 8px;
            background-color: $blue;
            color: $white;
            margin: 0 10px 0 5px;
            font-size: 14px;
            font-weight: 400;
            &:hover {
                color: $white;
            }
            &:disabled {
                background-color: $grey-light;
                cursor: not-allowed;
                &:hover {
                    box-shadow: none;
                    color: $white
                }
            }
        }
    }
}