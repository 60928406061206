.fade-enter-active, .fade-leave-active {
  transition: opacity .30s cubic-bezier(0.2, 0.68, 0.18, 1.08);
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.list-complete-item {
  transition: transform 0.5s;
  transition: visibility 0;
}

.list-complete-enter, .list-complete-leave-active {
  opacity: 0;
}

/* base */
.list-view {
  backface-visibility: hidden;
  transform-origin: 10% 50%;
  // z-index: 1;
}

/* moving */
.list-view-move {
  // transition: all 0.2s cubic-bezier(0.5, 0, 0.35, 1);
  transition: all 0.2s ease-in-out 25ms
}

/* appearing */
.list-view-enter-active {
  transition: all 0.2s ease-out;
}

/* disappearing */
.list-view-leave-active {
  transition: all 0.05s ease-in;
  position: absolute;
  z-index: 0;
}

/* appear at / disappear to */
.list-view-enter,
.list-view-leave-to {
  opacity: 0;
}

.list-view-enter {
  transform: scale(0.9);
}

.carousel-view {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  width: 24em;
  min-height: 25em;
}
.slide {
  flex: 0 0 20em;
  height: 20em;
  margin: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.1em dashed #000;
  border-radius: 50%;
  transition: transform 0.3s ease-in-out;
}
.slide:first-of-type {
  opacity: 0;
}
.slide:last-of-type {
  opacity: 0;
}