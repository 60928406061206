a:hover {
  text-decoration: none;
}

.t-primary {
  color: $text-primary;
}

a.t-primary {
  color: $text-primary !important;

  &:hover {
    color: $blue !important;
  }
}

.t-regular {
  color: $text-regular;
}

.t-secondary {
  color: $text-secondary !important;
}

a.t-secondary:hover {
  color: $blue !important;
}

.t-placeholder {
  color: $text-placeholder !important;
}

.t-black {
  color: $black;
}

.t-white {
  color: $white;
}

.t-white-darker {
  color: $grey-lightest;
}

.t-grey {
  color: $grey;
}

.t-highlight {
  color: $yellow-light;
}

.t-required {
  font-size: 0.9em;
  color: $text-secondary;
  font-style: italic;
}

.t-source-code {
  font-family: $font;
  font-size: 0.9em;
}

.t-blue-lightest {
  color: $blue-lightest;
}

.t-blue-light {
  color: $blue-light !important;
}

.t-blue {
  color: $blue !important;
}

p.t-blue-dark > a {
  color: $blue-dark !important;
}

.t-blue-dark {
  color: $blue-dark;
}

.t-green {
  color: $green-light !important;
}

.t-yellow {
  color: $yellow !important;
}

.t-yellow-light {
  color: $yellow-light;
}

.t-danger {
  color: $red;
}

.t-linkedin {
  color: #0077b5;
}

.t-transparent {
  color: $black-transparent;
}

.t-orcid {
  color: #9cc732;
}

.t-red {
  color: $red !important;
}

.t-xxxlr {
  font-size: 1.5em !important;
}

.t-xxlr {
  font-size: 1.4em !important;
}

.t-xlr {
  font-size: 1.3em !important;
}

.t-lr {
  font-size: 1.2em !important;
}

.t-md {
  font-size: 1.1em !important;
}

.t-sm {
  font-size: 0.9em !important;
}

.t-xs {
  font-size: 0.8em !important;
}

.t-xxs {
  font-size: 0.7em !important;
}

.t-xxxs {
  font-size: 0.6em !important;
}

.t-300 {
  font-weight: 300;
}

.t-400 {
  font-weight: 400;
}

.t-500 {
  font-weight: 500;
}

.t-600 {
  font-weight: 600;
}

.t-700 {
  font-weight: 700;
}

.t-800 {
  font-weight: 800;
}

.t-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.t-uppercase {
  text-transform: uppercase;
}

.t-italic {
  font-style: italic;
}

.t-compact {
  line-height: 1em;
}

.t-semi-compact {
  line-height: 1.3em;
}

.t-left {
  text-align: left;
}

.word-break {
  word-break: break-word;
}

.word-overflow {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.long-readable-text-preview  {
  max-height: 200px;
  overflow-y: auto;
  p {
    margin-bottom: 0px;
  }

  img {
    max-width: 100%;
  }
}

.long-readable-text-scroll {
  max-height: 400px;
  padding-right: 30px;
  overflow: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
  }

  &::-webkit-scrollbar {
    -webkit-appearance: scrollbartrack-vertical;
    width: 20px;
  }

  &::-webkit-scrollbar-thumb {
    border: 5px $white-dark solid;
    border-radius: 10px;
    background-color: $white-dark;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: $blue;
    }
  }
}

.is-truncated {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1.2rem;
    display: block;
    box-shadow: inset -1rem 0rem 4rem 0.25rem $white;
  }

  // --green
  @include m('line') {
    position: absolute;
    width: 50px;
    max-width: 50px;
    overflow: hidden;
    white-space: nowrap;
    word-wrap: break-word;
    word-break: break-all;
    background-image: linear-gradient(90deg,#000000 0%,rgba(0,0,0,0));
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
  }
}

.html-pre-wrap {
  white-space: pre-wrap;
  word-wrap: break-word;
}

at-placeholder:hover {
  color: $white !important;
}

.t-secondary-light {
  color: $text-secondary-light !important;
}

.t-no-select {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}