body {
  font-family: $font;
  color: $text-regular;
  font-size: 0.97em; // Change this to affect font scale across the entire website
  font-optical-sizing: auto;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5em;

  // hide scroll for mobile
  @include respondToWidth(t) {
    &.modal-open-mobile {
      overflow-y: hidden;
    }
  }
}

body.modal-open {
  overflow: hidden;
}

h1, h2, h3, h4 {
  color: $text-primary;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.4em;
}

h3 {
  font-size: 1.1em;
  font-weight: 400;
}

h4 {
  font-size: 1.1em;
}

mark {
  padding: 0;
}

a {
  color: $blue;

  mark {
    pointer-events: none;
    color: $blue !important;
  }
}

textarea {
  min-height: 48px;
}

object {
  pointer-events: none;
}

// placeholders
::-webkit-input-placeholder {
  color: $text-placeholder !important;
}

::placeholder {
  color: $text-placeholder !important;
}

:-moz-placeholder {
  color: $text-placeholder !important;
  opacity:  1;
}

:-ms-input-placeholder {
  color: $text-placeholder !important;
}

::-ms-input-placeholder {
  color: $text-placeholder !important;
}

textarea::-webkit-input-placeholder {
  color: $text-placeholder !important;
}

textarea:-moz-placeholder { /* Firefox 18- */
  color: $text-placeholder !important;
}

textarea::-moz-placeholder {  /* Firefox 19+ */
  color: $text-placeholder !important;
}

textarea:-ms-input-placeholder {
  color: $text-placeholder !important;
}

textarea::placeholder {
  color: $text-placeholder !important;
}
