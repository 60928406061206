$avatar-size: 114px;

.scoped-view {
  max-width: 100%;
  min-height: 100%;
  // overflow-x: hidden;

  .app-header {
    &_show {
      .navbar-header {
        margin: 15px 0;
      }
    }
  }

  .app-body {
    background: $white-dark;

     &_show {
      padding-top: calc(#{$header-height} *1.5);
      height: 100%;
    }
  }

  .btn-primary {
    width: 100%;
    max-width: 250px;
  }

  &_step {
    width: 60%;
    margin: 0 auto;

    @include respondToWidth(m) {
      margin-top: 2rem !important;
    }

    .skip {
      position: absolute;
      right: 0;
    }
  }

  &_container {
    position: relative;
    min-height: 420px;
    // max-height: calc(100vh - 180px);
    width: 100%;
    max-width: 60%;
    margin: 0 auto;
    border: none;
    background-color: $white;
    box-shadow: $box-shadow;

    @include respondToWidth(m) {
      max-width: 100%;
      min-height: auto;
    }

    .step-list {
      h1 {
        margin-top: 3rem !important;
        margin-bottom: 0px !important;

        @include respondToWidth(m) {
          margin-top: 0rem !important;
          font-size: 1.3em;
        }
      }
    }

    .card-header {
      background: none;
      border: none;
    }

    .card-footer {
      padding: 0 1.25rem 1.25rem 1.25rem;
    }

    .btn-scoped-view-flow-centered {
      float: none !important;
      background: $green;
      color: $white;
      border: $border-green-1;
      margin-left: calc(50% - 125px);
      margin-top: 50px;
    }

    .col-md-4:nth-child(-n+3) {
      margin-bottom: 15px;

      @include respondToWidth(m) {
        margin-bottom: 0px;
      }
    }

    .collaboration-container {
      .checkbox_name {
        padding-right: 0 !important;
      }
    }

    .types {
      text-align: center;

      .type-single {
        padding: 0.5em 0.75em;
        font-size: 1.1em;
        font-weight: 500;
        min-height: calc(170px + 1em);
        color: $text-primary;
        border: $border-base-1;
        border-radius: $border-radius;
        box-shadow: $box-shadow-bottom;

        .icon {
           height: 70px;
        }

        &:hover {
          cursor: pointer;
          border: $border-blue-1;
        }
      }

      @include respondToHeight(t) {
        .types {
          line-height: 100px;
        }

        .type-single {
          padding: 0.5em;
          min-height: 120px;

          .text {
            justify-content: center;
            align-items: center;
            display: flex;
            min-height: calc(115px - 1em);

            p {
              display: inline-block;
            }
          }

          .icon {
            display: none !important;
          }
        }
      }

      @include respondToWidth(m) {
        .type-single {
          min-height: 10px;
          margin-top: 15px;

          .icon {
            display: none !important;
          }
        }
      }

      .type-single.active {
        border: $border-blue-1 !important;
        color: $blue !important;

        i {
          color: $blue !important;
        }
      }
    }

    .about-avatar {
      text-align: center;

      .avatar-btn label {
        color: $blue-light;
        cursor: pointer;
      }

      .profile-picture .lbl-missing {
        float: none !important;
        display: block;
        margin: 0 auto;
        width: 114px;
      }

      .pp_missing_edit, .pp_avatar_edit {
        color: $blue;
        position: relative;
        width: inherit;
        border: none;
        background: transparent;
        top: 0;
        right: 0;
        text-align: center;


        i {
          font-size: 30px;
          padding-top: 20px;
          padding-bottom: 5px;
          color: $grey;
          opacity: .5;
        }

        span {
          display: block;
          font-size: 0.5em;
        }
      }

      .lbl-missing {
          float: none !important;
      }

      .pp_missing {
        width: $avatar-size;
        height: $avatar-size;
        line-height: $avatar-size;
        background: $green-light;
        font-weight: 500;
        float: none;
        margin: 0 auto;
        box-shadow: $box-shadow;
      }

      .pp_missing.clickable {
        background-color: $white-dark;
        color: $white-dark;
      }

      .pp_avatar {
        float: none;
        width: $avatar-size;
        height: $avatar-size;
        margin: 0 auto;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: $avatar-size;
          height: $avatar-size;
        }

        i {
          display: none;
        }
      }
    }

    .interest-container {
      min-height: 100%;
    }

    .interests-group {
      margin-top: 1.2rem;
    }

    .interests-selected {
      color: $grey;
      letter-spacing: 0;
      display: inline-block;
      border-radius: $border-radius;
      border: $border-base-1;
      box-shadow: $box-shadow;
      padding: 0.25em 1em;
      margin-right: 20px;
      margin-bottom: 20px;
      font-size: 1.1em;
      position: relative;

      i {
        position: absolute;
        z-index: 100;
        right: -12px;
        top: -12px;
        color: $blue;
        background: $white-dark;
        border: $border-base-1;
        width: 24px;
        height: 24px;
        border-radius: 48px;
        line-height: 21px;
        font-size: 0.7em;
        text-align: center;
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &:hover {
          cursor: pointer;
          background: $yellow;
        }
      }
    }
  }
}

.retake-onboarding-tour {
  // position: absolute;
  // bottom: -1.5rem;
  // right: 3rem;
  position: relative;

  @include respondToWidth(m) {
    display: none;
  }
}