.title-collapsible {
    background: $white-dark;
    border-radius: $border-radius;
    width: 100%;
    padding: 0.5rem 1rem;
    font-weight: 500;
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;
    display: block;
    user-select: none;
    transition: background-color 0.3s ease;

    .fa {
      font-size: 0.9em !important;
      padding-right: 0.5rem;
    }
    .disabled {
      cursor: not-allowed;
    }

    i, u {
      font-style: normal;
      text-decoration: none;
    }

    &:hover {
      cursor: pointer;
      background: $grey-lightest;
    }
  }

  .content-collapsible {
    padding-left: 1rem;

    li {
      margin-bottom: 1rem !important;
    }

    p {
      margin-bottom: 1.5rem !important;
    }

    &--parent {
      .title-collapsible {
        font-size: 1em !important;
        margin-top: 0;
      }

      .content-collapsible {
        margin-bottom: 1rem;
      }
    }
  }