/*
 * [ Placeholders CSS namings ]
 *
 * pl = placeholder
 * _m = meta
 * _f = filter
 * _c = content
 * --t = text
 * --tt = text-title
 * --i = input
 * --ts = text-small
 * --tm = text-medium
 * --tl = text-large
 * --tfw= text full width
 *
*/

.pl {
  &_m, &_c {
    height: 40px;

    &--t, &--i {
      width: 200px;
      height: 8px;
      margin-top: 7px;
      background: $grey-lightest;
      -webkit-animation: flickerAnimation 2s linear infinite;
      -moz-animation: flickerAnimation 2s linear infinite;
      -o-animation: flickerAnimation 2s linear infinite;
      animation: flickerAnimation 2s linear infinite;
      border-radius: $border-radius;
    }

    &--tt {
      height: 15px;
    }

    &--i {
      width: 100%;
      height: 30px;
    }

    &--tm {
      width: 350px;
    }

    &--ts {
      width: 120px;
    }

    &--tl {
      width: 350px;
    }

    &--tfw {
      width: 100%;
    }
  }

  &_m {
    border-radius: $border-base-1;
  }

  &_c {
    border-radius: $border-radius;
    height: 180px;
  }

  &_c--block {
    height: 225px;

    .pl_c--tl {
      width: 100%;
    }

    .pl_c--t {
      width: 100%;
    }

    .card-footer {
      border-top: none;
    }
  }

  &_c.no-border {
    border-left: none !important;
    border-right: none !important;
  }

  &_f {
    min-height: 320px;
  }
}

@keyframes flickerAnimation {
  0%   { opacity:0.5; }
  30%  { opacity:0.1; }
  100% { opacity:0.5; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:0.5; }
  30%  { opacity:0.1; }
  100% { opacity:0.5; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:0.5; }
  30%  { opacity:0.1; }
  100% { opacity:0.5; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:0.5; }
  70%  { opacity:0.1; }
  100% { opacity:0.5; }
}
