.highlight-background, .highlight-background--delayed {
  animation-name: animate;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
  -moz-animation-name: animate;
  -moz-animation-duration: 1.5s;
  -moz-animation-iteration-count: 1;
  -o-animation-name: animate;
  -o-animation-duration: 1.5s;
  -o-animation-iteration-count: 1;
  -webkit-animation-name: animate;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-iteration-count: 1;
}

.highlight-background--delayed {
  animation-delay: .5s;
  -moz-animation-delay: .5s;
  -o-animation-delay: .5s;
  -webkit-animation-delay: .5s;
}

@keyframes animate {
  from { background: $white }
  50% { background: $blue-transparent; }
  100% { background: $white; }
}

@-webkit-keyframes animate {
  from { background: $white; }
  50% { background: $blue-transparent; }
  100% { background: $white; }
}

@-o-keyframes animate {
  from { background: $white; }
  50% { background: $blue-transparent; }
  100% { background: $white }
}


// Highlight overlay (used for highlighting 'interesting' filter sections)
.highlight-overlay {
  position: relative
}

.highlight-overlay::after {
  animation-name: animate-overlay;
  animation-duration: 1s;
  animation-iteration-count: 2;
  animation-delay: .5s;
  animation-timing-function: ease-in-out;

  pointer-events: none;
  content: ""; // ::before and ::after both require content
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@keyframes animate-overlay {
  from { background: transparent; }
  50% { background: $blue-transparent; }
  100% { background: transparent; }
}
