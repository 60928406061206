.contact {
  height: 26px;

  .is-truncated--line {
    left: 25px;
  }

  .show {
    color: $text-secondary !important;
    text-decoration: underline;
    position: absolute;
    right: 0rem;
  }
}

.contact-in-modal {
  position: relative;

  .show {
    color: $text-secondary !important;
    right: 0px;
  }
}

$row-content-width: 40px;
$row-content-width-text: 100px;
$row-content-width-small: 28px;
$row-content-width-smaller: 24px;

.row-content {
  &_text {
    position: relative;
    .row-content--side {
      display: none;
    }

    .row-content--main {
      width: 100%;
      padding-left: 130px;

      .t-500:first-of-type, .t-400:first-of-type {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 130px;
      }
    }

    @include m('large') {
      @extend .row-content_text;
      .row-content--main {
        padding-left: 150px;

        .t-500:first-of-type, .t-400:first-of-type {
          width: 150px;
        }
      }
    }

    @include m('tight') {
      @extend .row-content_text;
      .row-content--main {
        padding-left: 55px;

        .t-500:first-of-type, .t-400:first-of-type {
          width: 55px;
        }
      }
    }
  }

  &_inline {
    margin-left: 10px;
    .row-content--side {
     width: 30px;
    }

    .row-content--main {
      width: calc(100% - 30px);
    }
  }

  &_inline--small {
    .row-content--side {
      width: 20px;
    }

    .row-content--main {
      width: calc(100% - 20px);
    }
  }

  &_small {
    line-height: 1.2em;

    .row-content--main {
      padding-top: 2px;
    }
  }

  &--side {
    position: relative;
    float: left;
    min-height: 1px;
    width: $row-content-width;
    color: $text-secondary;
    font-size: 0.9em;
    line-height: 24px;

    i {
      padding-right: 0.25rem;
    }
  }

  &--main {
    float: left;
    width: calc(100% - #{$row-content-width});
  }

  .flag-positioning {
    line-height: 0.9em;
    width: 1.2em;
  }

  &:after {
    display: block;
    clear: both;
    content: "";
  }
}

.profile_header {
  min-height: 100px;

  h1 {
    font-size: 2em;
  }

  &_content {
    &_show {
      max-width: $container-max-width;
    }

    .rounded-circle {
      width: 60px;
      height: 60px;
      line-height: 65px;

      &--link {
        width: 16px;
        height: 16px;
        line-height: 16px;
        font-size: 0.7em;
        color: $white;
        background: $blue;
        border-radius: 50%;

        &:hover {
          text-decoration: none;
          background: $green-light;
        }
      }
    }
  }

  nav.nav {
    box-shadow: $box-shadow-bottom;
    box-sizing: border-box;
    position: relative;
    min-height: $nav-height;
    max-height: $nav-height;
    background: $white;
    text-align: center;
  }

  &_nav {
    &_item {
      cursor: pointer;
      display: inline-block;

      position: relative;
      margin-left: 2px;
      margin-right: 2px;
    }
  }
}

.research_group, .researcher {
  .invite-box {
    .dropdown-menu-custom--left {
      top: 32px;
      right: -1.33rem;
      left: -1.30rem;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .arrow-top-right:before, .arrow-top-right:after {
      right: 16px;
    }

    .bookmark-trigger {
      .arrow-top-right:after, .arrow-top-right:before {
        right: calc(100% - 1.25rem - 13px);
      }

      .dropdown-menu-custom {
        top: 32px;
        right: -1.33rem;
        left: -1.30rem;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
}

.mobile-container {
  padding: 3rem;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: $white;
}

.thumbnail-block {
  .avatar {
    display: block;
    width: 30px;
    height: 30px;
    float: left;
  }

  .name {
    display: block;
    float: left;
    width: calc(100% - 30px);
    padding-left: 0.5rem;
  }

  .pp_missing, .pp_avatar {
    height: 30px !important;
    width: 30px !important;
    top: calc(0.5rem + 2px) !important;
    left: 1.25rem !important;
    background: $grey-light;
    box-shadow: $box-shadow;
    position: absolute;
    float: none;
    font-size: 0.8em;
    margin-right: 10px;
  }

  .pp_missing {
    border-radius: $border-radius;
    line-height: 31px !important;
  }

  .pp_avatar {
    line-height: 28px !important;
  }
}

.cta-add-item {
  margin-top: 1rem;
  height: 40px;
  line-height: 40px;

  i {
    border: $border-base-1;
    border-radius: $border-radius;
    width: 40px;
    line-height: 40px;
    height: 40px;
    text-align: center;
    transition: all .3s;
    float: left;
  }

  a {
    width: calc(100% - 40px);
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
  }

  &:hover {
    i {
      cursor: pointer;
      background: $blue-lightest;
    }
  }
}
