.switches {
  position: relative;
  display: inline-block;

  &_label {
    display: block;
    max-width: 90%;
  }

  input {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    z-index: 1;
    cursor: pointer;
  }

  &_style {
    height: 14px;
    width: 36px;
    position: relative;
    border-radius: 30px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    transition: linear .2s, background-color linear .2s;
    margin-top: 5px;

    border: $border-base-1;
    background: $white;

    &:after {
      content: '';
      height: 18px;
      width: 18px;
      border-radius: 100px;
      display: block;
      transition: linear .15s, background-color linear .15s;
      position: absolute;
      left: 100%;
      margin-left: -16px;
      cursor: pointer;
      top: -3px;
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
      background: $blue;
      border: $border-blue-1;
    }

    &--light {
      border: $border-base-1;
      background: $white;

      &:after {
        background: $white;
      }
    }

  }

  &_unchecked {
    .switches_style {
      justify-content: flex-end;
      background: $white;
      border: $border-base-1;

      &:after {
        left: 15px;
        background: $white;
        border: $border-base-1;
      }
    }
  }

  &_disabled {
    .switches_style {
      opacity: .3;
    }

    input {
      cursor: not-allowed;
    }
  }

  &_bold {
    .switches_style {
      top: -8px;
      height: 26px;
      width: 51px;

      &:after {
        margin-left: -24px;
        top: 3px;
      }
    }

    &--unchecked {
      .switches_style {
        &:after {
            left: 28px;
        }
      }
    }

    .switches_label {
      span {
        padding-bottom: 7px;
        display: inline-block;
      }
    }
  }
}
