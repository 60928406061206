.link-button {
  padding: 0.25rem 0.5rem;
  border-radius: $border-radius;
  background: transparent;
  border: none;
  text-align: left;
  transition: background-color .10s ease-out;
  display: flex;
  color: $blue;

  i {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    color: $grey-light;
  }

  i.with-text {
    width: 20px;
    margin-right: 0.25rem;

    &.t-lr {
      width: 24px;
    }

    &.t-sm {
      width: 16px;
    }
  }

  span {
    white-space: nowrap;
    font-family: $font;

    &.text-multiline {
      white-space: normal;
    }
  }

  &:hover, &:active {
    background: $blue-lightest;
    color: $blue;
    i {
      color: $blue;
    }
  }

  &:disabled {
    pointer-events: none;
  }
}

.filter-card-tooltip {
  box-shadow: $box-shadow-hover;
  z-index: 2;
  border-radius: 4px;

  .tooltip-inner {
    background: $white!important;
    color: $grey;
    padding: 0.5rem 1.3rem!important;
    border: none;
    text-align: left;
    max-height: 400px;
    overflow-y: auto;
    border-radius: 4px !important;
  }

  &.no-padding {
    .tooltip-inner {
      padding: 0 !important;
    }
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 10px;
    border-color: $white!important;
    z-index: 0;
    overflow: hidden;
  }

  &[x-placement^="right"] .tooltip-arrow {
    margin-left: 6px;
    left: -6px!important;
  }
  &[x-placement^="right"] .tooltip-inner {
    margin-left: -2px;
  }
}

.graph-legend-tooltip {
  @extend .filter-card-tooltip;
  z-index: 0;
}

.landscape-card-tooltip {
  @extend .filter-card-tooltip;
  &.tooltip, &.popover {
    width: auto!important;
    max-width: fit-content!important;
    font-size: 1rem;

    .tooltip-inner {
      width: auto!important;
      max-width: fit-content!important;
      overflow: hidden;
    }
  }

}

.tooltip[x-placement^="top"] .tooltip-arrow {
  transform: translateY(-1px);
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  transform: translateY(1px);
}

// Dropdown component
.dd-wrapper {
  .trigger {
    display: block !important;
  }

  &.dd-wide {
    .popover {
      max-width: 800px;

      .tooltip-inner {
        max-width: 100vw;
      }
    }
  }

  .wrapper {
    -webkit-box-shadow: 0px 0px 30px 0px rgba(49,81,114,0.3);
    -moz-box-shadow: 0px 0px 30px 0px rgba(49,81,114,0.3);
    box-shadow: 0px 0px 30px 0px rgba(49,81,114,0.3);
    border-radius: 4px;
  }

  .wrapper, .tooltip {
    font-size: 1rem;
    max-width: 400px;

    &:focus {
      outline:0;
    }

    .tooltip-inner {
      background-color: $white !important;
      color: $grey;
      border-radius: $border-radius;
      padding: 0.5rem !important;
      box-shadow: none !important;
      border: 1px solid $border-base;
      text-align: left;

      max-height: 400px;
      overflow-y: auto;
      max-width: unset;
    }

    &.no-padding {
      .tooltip-inner {
        background-color: $white !important;
        color: $grey;
        border-radius: $border-radius;
        padding: 0 !important;
        box-shadow: none !important;
        border: 1px solid $border-base;
      }
    }

    .tooltip-arrow {
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 10px;
      border-color: $white !important;
      z-index: 1;
    }

    .no-arrow {
      display: none;
    }

    .link-button {
      width: 100%;
      display: flex;
      align-items: center;
      color: $grey;

      &:visited {
        color: $grey!important;

        &:hover, &:active {
          color: $blue!important;
        }
      }

      &:hover, &:active {
        color: $blue;
      }
    }
  }
}

.dropdown-item {
  padding: 0.25rem 1rem;
  color: $text-primary;
  transition: background .10s ease-out;

  &:active {
    color: $blue !important;
    background: $white-dark;

    .t-regular {
      color: $blue !important;
    }
  }

  &:hover {
    color: $blue !important;
    background: $blue-transparent;
  }

  &.disabled {
    cursor: initial;
  }
}

.dropdown-disable-hover:hover {
  background: $white;
}

.dropdown-menu-custom {
  // --left
  @include m('left') {
    @extend .dropdown-menu-custom;
    left: 0px;
    right: 0px;
    top: 50px;

    @include m('top-fix') {
      @extend .dropdown-menu-custom--left;

      top: 41px !important;
    }
  }

  &--left.arrow-top-right:before, &--left.arrow-top-right:after {
    top: -17px;
  }

  // --top
  @include m('top') {
    @extend .dropdown-menu-custom;
    left: 0px;
    right: 0px;
    top: auto;
    bottom: 50px;
  }

  &--top.arrow-top-right:before, &--top.arrow-top-right:after {
    top: auto;
    bottom: -16px;
    transform: rotate(180deg);
  }

  &--top.arrow-top-right:before {
    bottom: -18px !important;
  }

  // --bottom
  @include m('bottom') {
    @extend .dropdown-menu-custom;
    left: auto !important;
    right: 0px !important;
    top: 40px !important;
    bottom: auto !important;
  }
}

.dropdown-editable {
  .arrow-top-right:before, .arrow-top-right:after {
    top: -17px;
  }

  .dropdown-menu-custom {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    top: 27px;
    right: -10px;
    min-width: 150px;
  }

  .dropdown-menu-custom a:active {
    color: $blue !important;
  }
}

.dropdown-announcement-title {
    font-size: 0.85rem;
    max-width: 250px;
    float: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.select-menu-modal-holder {
  width: 300px;
  max-width: 300px;
  overflow: auto;

  .select-menu-header {
    &:active {
      color: $text-primary !important;
    }
  }

  .select-menu-item {
    text-align: left;
    display: block;
    white-space: normal;

    .visibility-hidden {
      visibility: hidden;
    }

    &_icon {
      display: block;
      width: 27px;
      margin: 0 !important;
    }

    &_text {
      width: calc(100% - 27px);
      max-width: calc(100% - 27px);
      display: block;
      padding: 0.25rem 0;

      .t-secondary {
        line-height: 17px;
      }

      span {
        display: block;
      }
    }

    &:hover {
      background: $white-dark;
    }
  }
}

.bookmark-dd {
  @include respondToWidth(t) {
    width: 350px;
  }

  @include respondToWidth(m) {
    width: 300px;
  }
}
