.landscape-item {
  transition: background .10s ease-out;
  position: relative;
  height: 49px;
}

// Landscape items with a link
a.landscape-item {
  .landscape-card-count-bg {
    background-color: $blue-transparent;
  }
  &:hover {
    background: $blue-transparent;
  }
}

// Landscape items without a link
div.landscape-item {
  .landscape-card-count-bg {
    background-color: #F7F7F7;
  }
  &:hover {
    background: transparent;
  }
}

// Background partial fill to show relative counts per list
.landscape-card-count-bg {
  z-index: 0;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 4px;
}

// Make sure content is shown over the count-bg, rather than "behind" it
.landscape-card-content {
  & > span, & > i, & > div {
    z-index: 1;
  }
}