.multiselect__placeholder {
  margin: 0px;
  padding: 0px;
  color: $text-placeholder;
  line-height: 20px;
  padding-left: 5px;
}

.multiselect__tags {
  font-size: 1em;
}

.multiselect__tag {
  background: $green;
  margin-bottom: 0px;
}

.multiselect__option--highlight {
  background: $green;

  &:after {
    background: $green;
  }
}
.multiselect__spinner:after, .multiselect__spinner:before {
  border-color: $green transparent transparent;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: $red;
}

.multiselect__content-wrapper {
  z-index: 11
}

.multiselect__content {
  max-width: 100%;
}
