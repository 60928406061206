.is-hover {
  cursor: pointer;

  @include m('red') {
    @extend .is-hover;
    &:hover {
      color: $red !important;
    }
  }

  @include m('blue') {
    @extend .is-hover;
    &:hover {
      fill: $blue !important;
      color: $blue !important;
    }
  }

  @include m('icon') {
    @extend .is-hover;
    &:hover {
      color: $blue !important;

      i {
        color: $blue !important;
      }
    }
  }
}

.disable-content {
  opacity: 0.5;
  pointer-events: none;
}

.semi-transparent {
  opacity: 0.5;
}

.al-logo-text {
  font-size: 0.8em;
  font-weight: 700;
  @include respondToWidth(m) {
    font-size: 0.7em;
  }
}

.white-space-reset {
  white-space: normal !important;
}

.line-after-text {
  display: flex;
  align-items: center;

  span {
    margin-left: 1rem;
    content: "";
    flex: 1 1 auto;
    border-top: $border-base-1;
  }
}

.capitalize {
  text-transform: capitalize;
}


.al-logo-h1 {
  img {
    transform: rotate(90deg);
    margin-right: 0.75rem;
  }

  span {
    font-size: 0.7em;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
}

.is-hover-disabled {
  &:hover {
    background: $white;
    cursor: default;
  }

  &:active {
    background: $white !important;
    cursor: default;
  }
}

.warning-block-inline {
  margin-left: -1.25rem;
  width: calc(100% + 2.5rem);
  padding: 1.25rem;
}

.show-mobile {
  display: none;

  @include respondToWidth(m) {
    display: block;
  }
}

.cursor-move {
  cursor: move;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-disabled {
  pointer-events: none;
}

.p-reset {
  padding: 0 !important;
}

.m-reset {
  margin: 0 !important;
}

.box-shadow {
  box-shadow: $box-shadow;
}

.box-shadow-wide {
  -webkit-box-shadow: 0px 10px 15px 0px rgba(49,81,114,0.3);
  -moz-box-shadow: 0px 10px 15px 0px rgba(49,81,114,0.3);
  box-shadow: 0px 10px 15px 0px rgba(49,81,114,0.3);
}

.hr-small {
  width: 40px;
  border-top: $border-base-3;
  margin: 1em auto 1.5em 0px;
}

.hr-outlined {
  width: calc(100% + 2.6rem);
  margin-left: -1.3rem;

  @include m('small') {
    width: calc(100% + 2.4rem);
    margin-left: -1.2em;
  }
}

.flag-icon {
  background-size: cover;
}

.block-fa {
  i:first-of-type {
    text-align: center;
    display: block;
    width: 18px;
    float: left;
    margin: 5px 7px 0px 0px
  }

  a:first-of-type, span:first-of-type {
    float: left;
    width: calc(100% - 25px);
  }
}

.block-fa-span {
  i:first-of-type {
    text-align: center;
    display: block;
    width: 18px;
    float: left;
    margin: 5px 7px 0px 0px;
    position: relative;
    top: auto;
    right: auto;
  }

  .block-span {
    float: left;
    width: calc(100% - 25px);
  }
}

.fa-spin-left {
  position: absolute;
  left: 8px;
  top: 12px;
}

.disabled-overflow {
  overflow-y: inherit !important;
}

.disabled {
  cursor: initial;
}

.fa-info-absolute {
  position: absolute;
  top: 5px;

  &--right {
    right: 0px;
  }

  &--navbar {
    padding-left: 3px;
  }
}

.is-edit-pencil {
  &:hover {
    cursor: pointer;
    color: $text-secondary !important;
  }

  &--right {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}

.rotate-90 {
  transform: rotate(90deg);
}

.pull-none {
  float: none;
}

.arrow-top-right {
  &:before, &:after {
    content: " ";
    position: absolute;
    top: 0px;
    right: 10px;
    pointer-events: none;
    border: 8px solid transparent;
  }

  &:before {
    border-bottom-color: $border-base;
  }

  &:after {
    margin-top: 1px;
    margin-left: 2px;
    border-bottom-color: $white;
  }
}


.iframe-wrapper {
  iframe {
    display:block !important;
  }
}

.card-bullet-list {
  .list-inline-item {
    margin-right: 0px ;
  }

  .list-inline-item:not(:first-child) {
    position: relative;

    &:before {
      content: '';
      margin-left: 8px;
      margin-right: 8px;
      float: left;
      width: 6px;
      height: 6px;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px;
      background-color: $green;
      margin-top: 10px;
    }
  }
}

.min-height--100vh {
  min-height: 100vh;
  height: 100vh;
}

.font-style-editable {
  span {
    &:hover {
      color: $blue !important;
      cursor: pointer;
    }
  }
}


.no-border {
  border: 0 !important;
}

.invisible {
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
}

$unit-map: (per: '%', px: 'px' );

// Generate classes for width--5px/per to width--100px/per in steps of 5
@for $size from 1 through 20 {
  $attr-value: 5 * $size;

  @each $unit, $ut in $unit-map {
    .width--#{$attr-value}#{$unit} {
      width: #{$attr-value}#{$ut} !important;
      max-width: #{$attr-value}#{$ut} !important;
      min-width: #{$attr-value}#{$ut} !important;
    }

    .min-height--#{$attr-value}#{$unit} {
      min-height: #{$attr-value}#{$ut} !important;
    }
  }
}

// Generate classes for width--105px to width--300px in steps of 5
@for $size from 21 through 60 {
  $attr-value: 5 * $size;

  .width--#{$attr-value}px {
    width: #{$attr-value}px !important;
    max-width: #{$attr-value}px !important;
    min-width: #{$attr-value}px !important;
  }
}

@for $size from 2 through 10 {
  $attr-value: 50 * $size;

  @each $unit, $ut in $unit-map {
    .height--#{$attr-value}#{$unit} {
      height: #{$attr-value}#{$ut} !important;
    }

    .min-height--#{$attr-value}#{$unit} {
      min-height: #{$attr-value}#{$ut} !important;
    }
  }
}

.height--25px {
  height: 25px !important;
}

@for $size from 1 through 20 {
  $attr-value: 5 * $size;

  @each $unit, $ut in $unit-map {
    .margin-t--#{$attr-value}#{$unit} {
      margin-top: #{$attr-value}#{$ut} !important;
    }
  }
}


.hide-for-touch {
  @media (hover: none) {
    display: none !important;
  }
}

.vertical-divider {
  height: 30px;
  width: 1px;
  background-color: $grey-lighter;
}

.alternating-li-bg-grey {
  li:nth-of-type(odd) {
    background-color: $white-light;
  }

  li:nth-of-type(even) {
    background-color: transparent;
  }
}

.markdown p, ol, ul {
  white-space: normal;
}