.badge-primary {
  border: $border-base-1;
  color: $text-regular;
  background: $white;
  font-weight: 400;
  font-size: 0.9em;
  padding: 0.3rem 0.5rem;

  &:hover {
    border-color: $blue;
    color: $blue;
  }

  &.is-active {
    background-color: $blue;
    border-color: $blue;
    color: $white;
  }

  @include m('blue-bordered') {
    @extend .badge-primary;
    color: $blue;
    border-color: $blue;

    &:hover {
      background: $blue;
      color: $white;
    }
  }
}

.badge-secondary {
  border: $border-base-1;
  background: $white-dark;
  font-weight: 400;
  font-size: 0.9em;
  padding: 0.3rem 0.5rem;


  @include m('blue') {
    @extend .badge-secondary;
    color: $text-primary;
    background: $blue-transparent;
    padding: 0.3rem 0.5rem;
  }

  @include m('white') {
    @extend .badge-secondary;
    color: $text-regular;
    background: $white;
    padding: 0.3rem 0.5rem;
  }

  @include m('grey') {
    @extend .badge-secondary;
    color: $white;
    background: $white;
    padding: 0.3rem 0.5rem;
  }

  @include m('grey-light') {
    @extend .badge-secondary;
    color: $white;
    background: $grey-light;
    padding: 0.3rem 0.5rem;
  }

  @include m('highlight') {
    @extend .badge-secondary;
    // color: $text-primary;
    background: $yellow-light;
    padding: 0.3rem 0.5rem;

  }
}

.badge-beta {
  background: $yellow;
  border-radius: $border-radius;
  color: $white;
  font-size: 0.6em;
  text-transform: uppercase;
  padding: 0px 4px;
  font-weight: 600;
  line-height: 1.4em;
  position: absolute;
  left: 0px;
  width: 35px;
  box-shadow: $box-shadow;
  margin-top: -5px;
}

.badge-secondary[href]:hover {
  background: $white-dark;
}

.badge-secondary[href]:visited {
  color: $text-regular !important;
}

.badge-secondary.is-hover-delete {
  &:hover {
    cursor: pointer;
    background: $white;
    color: $red;
  }
}

.badge-secondary.is-hover-add {
  &:hover {
    cursor: pointer;
    transform: translateY(-1px);
    background: $white;
    color: $green;
  }
}

.badge-highlight {
  @extend .badge-warning !optional;
  background: $yellow-light;
  border: $border-base-1;

  &:hover {
    background: $white !important;
  }
}

.badge-overflow {
  overflow-x: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  float: left;
}

.y-a-label {
  background-color: #F8FAFA;
  border-radius: 4px;

  &:hover {
    background-color: $blue-lightest;
  }
  
  button {
    color: $grey;
    &:focus {
      color: $blue;
    }
  }
}