.question, .answer, .comment {
  h2 {
    font-size: 1.5em;
  }

  .btn {
    width: 150px;
  }

  .btn-main {
    width: auto;
  }
}

.question {
  &_avatar {
    width: calc(40px + 1rem) !important;
    max-width: calc(40px + 1rem) !important;
  }

  &_title {
    width: calc(100% - 40px - 1rem) !important;
    max-width: calc(100% - 40px - 1rem) !important;
    margin-top: -4px;

    .profile-link a {
      color: $text-regular;
    }
  }

  .col-md-9 {
    flex: 0 0 calc(100% - 16.66667% - 25px) !important;
    max-width: calc(100% - 16.66667% - 25px) !important;
  }

  .col-md-3 {
    flex: 0 0 calc(16.66667% + 25px) !important;
    max-width: calc(16.66667% + 25px) !important;
  }
}

.answer {
  &_avatar {
    max-width: calc(40px + 1rem) !important;
  }

  &_name {
    line-height: 15px;

    a {
      color: $text-regular;
    }
  }

  &_count {
    margin-left: 1.25rem;
  }
  
  &_timestamp {
    line-height: 18px;
  }

  .col-md-2 {
    flex: 0 0 calc(16.66667% + 25px) !important;
    max-width: calc(16.66667% + 25px) !important;
  }
}

.answer-create, .message-create {
  .ql-container, .ql-editor {
    min-height: 200px;
  }
}

.comment {
  &_avatar {
    max-width: calc(40px + 15px) !important;
  }

  &_name {
    line-height: 15px;

    a {
      color: $text-regular;
    }
  }
  
  &_timestamp {
    line-height: 18px;
  }

  .col-md-11 {
    flex: 0 0 calc(100% - 55px) !important;
    max-width: calc(100% - 55px) !important;
  }
}

.comment.is-hover {
  &:hover {
    cursor: pointer;
    background: $blue-transparent;
  }
}
