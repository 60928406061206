.create-checkbox label {
  font-size: .9em;
}

.create-card {
  height: 100%;
  width: 100%;
  text-align: center;

  @include respondToWidth(t) {
    // min-height: 500px;
    margin: 0 auto;
  }

  .card-image {
    margin-top: 3.5rem;
    margin-bottom: 2rem;

    img {
      width: 100%;
      max-width: 300px;
      height: 185px;
      max-height: 185px;
    }
  }
}

.create-card-form {
  width: 100%;

  @include respondToWidth(t) {
    text-align: left;
    margin: 0 auto;
    margin: 0 auto;
  }

  .dropdown-menu-custom--button {
    .dropdown-label {
      max-width: 95%;
      overflow: hidden;
    }

    .dropdown-angle {
      margin-top: 4px;
    }
  }

  .dropdown-menu-custom {
    padding: 8px 0;
    top: 34px;
    width: 100%;
    right: 0;
    max-height: 215px;
    overflow-y: scroll;
    text-align: left;

    .dropdown-item {
      color: $black;;
      cursor: pointer;
    }
  }

  ::-webkit-scrollbar {
    width: 24px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
  }

  ::-webkit-scrollbar-thumb {
    border: 10px #fff solid;
    border-radius: 12px;
    background-color: #d8d8d8;
  }
}

.create-card-bottom,
.create-card-bottom--started {
  width: 100%;
  //margin-bottom: 50px;
  
  h2 {
    margin-bottom: 1rem;
  }

  ul {
    min-height: 120px;
    padding: 0 1.8rem;
  }

  p {
    padding: 0 5rem;

    @include respondToWidth(t) {
      padding: 0 3.125rem;
    }
  }

  .btn-primary, .btn-secondary {
    text-align: center;
    width: 100%;
    max-width: 90%;
    display: inline-block;
    position: relative !important;
    bottom: 0;
    left: 0px !important;
  }

  .create-card-bottom__buttons {
    bottom: 0;
    position: absolute;
    width: 100%;
    .btn-secondary {
      bottom: 0;
      left: 0 !important;
      position: relative !important;
    }
  }
}

.create-card-bottom--started {
  bottom: 40px;
}

.create-card-bottom--soon {
  min-height: auto;
}
