/* Borders */
$border-white-1: 1px solid $white;
$border-white-2: 2px solid $white;
$border-white-3: 3px solid $white;

$border-danger-1: 1px solid $red;

$border-green-1: 1px solid $green;
$border-green-light-1: 1px solid $green-light;

$border-blue-1: 1px solid $blue;
$border-blue-2: 2px solid $blue;
$border-blue-3: 3px solid $blue;
$border-blue-dark-1: 1px solid $blue-dark;

$border-base-1: 1px solid $border-base;
$border-base-2: 2px solid $border-base;
$border-base-3: 3px solid $border-base;

$border-yellow-1: 1px solid $yellow;
$border-grey-lighter-1: 1px solid $grey-lighter;

/* Widths */
$sidebar-width: 67px;
$container-max-width: 1500px;

/* Heights */
$header-height: 55px;
$footer-height: 58px;
$search-height: 100px;
$sub-header-height: 160px;
$nav-height: 55px;

/* Transitions */
$speed-default: 0.3s ease;
$speed-slower: 0.6s ease;

/* Box shadow */
$box-shadow-blue: 0 0 4px rgba(59,153,240,0.8);
$box-shadow: 0 1px 2px rgba(71,80,89,.15);
$box-shadow-hover: 0 10px 15px -5px rgba(71,80,89,.15), 0 1px 2px 0 rgba(71,80,89,.15);
$box-shadow-bottom: 0 2px 3px -2px rgba(25,25,25,0.2);
$box-shadow-bottom-right: 0 7px 14px rgba(50,50,93,.1), 0 3px 6px rgba(0,0,0,.08);

/* Border radius */
$border-radius: .25rem !default;

/* Bootstrap */
// container
$grid-breakpoints: (
  xxs: 0,
  xs: 590px,
  sm: 786px,
  md: 900px,
  lg: 1024px,
  xl: 1280px,
  xxl: 1626px
) !default;

$container-max-widths: (
  xxs: 375px,
  xs: 560px,
  sm: 756px,
  md: 868px,
  lg: 1090px,
  xl: 1312px,
  xxl: 1534px
) !default;

// border colors
$border-color: $border-base;
$input-border-color: $border-base;
$list-group-border-color: $border-base;
$card-border-color: $border-base;
$modal-content-border-color: $border-base;

// button colors
$primary: $blue;
$secondary: $white;

// Font
$font: "Figtree", "Roboto", "Helvetica", sans-serif;