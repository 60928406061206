.community {
  .owner-box {
    .t-overflow {
      width: calc(100% - 120px)
    }

    .is-hover {
      &:hover {
        .pp_missing {
          background: $blue-lightest;
        }
      }
    }
  }

  .thumbnail-top {
    .pp_missing, .pp_avatar {
      top: 0px !important;
      left: 0px !important;
    }
  }

  .sub-communities {
    .title {
      width: 80%;
      padding-left: 10px;
    }
  }

  .settings-inline {
    margin-left: 21px;
  }

  .community-picture {
    height: calc(#{$sub-header-height - 40px}) !important;
    width: calc(#{$sub-header-height - 40px}) !important;

    .pp_missing {
      background: $grey-light;
    }

    .pp_missing, .pp_avatar {
      height: calc(#{$sub-header-height - 40px}) !important;
      width: calc(#{$sub-header-height - 40px}) !important;
      line-height: calc(#{$sub-header-height - 40px}) !important;
      box-shadow: $box-shadow;

      &_edit {
        background: $white;
        color: $blue;
        box-shadow: $box-shadow;
        width: 35px;
        height: 35px;
        line-height: 25px;

        i {
          line-height: 31px;
        }
      }
    }
  }
}
