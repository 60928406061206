.quill-editor {
  background: $white;
}


.ql-toolbar.ql-snow {
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  border: $border-base-1 !important;
}

.ql-snow .ql-stroke {
  stroke: $text-primary !important;
}

.ql-container.ql-snow {
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  border: $border-base-1 !important;
  border-top: none !important;
  font-size: 1em !important;
  font-family: $font;

  ol, ul, dl {
    padding-left: 0px;
  }
}

.ql-snow .ql-editor pre.ql-syntax {
  background-color: rgba(27,31,35,0.05);
  color: $text-primary;
}

.ql-container, .ql-editor {
  min-height: 100px;
}

.ql-editor {
  padding: 0.375rem 0.75rem
}

.ql-editor.ql-blank::before {
  font-style: normal;
  left: 0.75rem;
  color: $text-placeholder !important;
}

.is-rich-text {
  overflow: hidden;
  font-size: 1em;
  color: $text-primary !important;

  h1 {
    font-size: 1.3em !important;
    font-weight: 700 !important;
    margin-bottom: 0px !important;
  }
  
  h2 {
    font-size: 1.2em !important;
    font-weight: 600 !important;
    margin-bottom: 0px !important;
  }
  
  h3 {
    font-size: 1.1em !important;
    font-weight: 500 !important;
    margin-bottom: 0px !important;
  }

  p {
    margin-bottom: 0px !important;
  }

  .ql-align-center {
    text-align: center;
  }

  p {
    color: $text-primary !important;

    span {
      color: $text-primary !important;
    }
  }

  ol, ul, dl {
    padding-left: 30px;
  }

  blockquote {
    border-left: 4px solid $grey-lightest;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px;
  }

  pre {
    padding: 0.2em 0.4em;
    margin: 0;
    font-size: 85%;
    background-color: rgba(27,31,35,0.05);
    border-radius: 3px;
  }

  img {
    max-width: 100%;
  }
}

.quill-editor.is-invalid {
  .ql-toolbar.ql-snow, .ql-container.ql-snow {
    border-color: $red !important;
  }
}

.quill-editor.is-invalid ~ .invalid-feedback {
  display: block;
}

.quill-editor.is-invalid ~ .fa-warning {
  top: 46px;
}

.quill-editor.hide-toolbar .ql-toolbar {
  display: none;
}

.quill-editor.hide-toolbar .ql-container {
  border-top: $border-base-1 !important;
  border-radius: $border-radius !important;
}


