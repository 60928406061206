.timeline {
  &_record {
    position: relative;
    display: flex;

    &--left {
      width: 40%;
    }

    &--right {
      position: relative;
      width: 50%;
      border-left: $border-base-1;

      &:before {
        content: " ";
        position: absolute;
        top: 10px;
        left: -5px;
        height: 9px;
        width: 9px;
        border-radius: 10px;
        background-color: $white;
        border: $border-base-1;
      }
    }

    &--buttons {
      position: absolute;
      right: 0px;
      top: 0px;

      i {
        &:hover {
          cursor: pointer;
          color: $text-secondary !important;
        }
      }
    }
  }
}
