.v-spinner  {
  position: relative;
  display: flex;
  margin: 0 auto;
  align-items: center;

  .v-pulse {
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.2, 0.68, 0.18, 1.08);
    animation-iteration-count: infinite;
    animation-duration: 0.75s;
    animation-name: v-pulseStretchDelay;
    display: inline-block;
    border-radius: 100%;
    margin: 2px;
    height: 10px;
    width: 10px;
    background-color: $grey-lighter;
  }

  .v-pulse1 {
    animation-delay: 0.12s;
  }

  .v-pulse2 {
    animation-delay: 0.24s;
  }

  .v-pulse3 {
    animation-delay: 0.36s;
  }
}

.v-spinner-sm {
  .v-pulse {
    height: 6px;
    width: 6px;
  }
}

@-webkit-keyframes v-pulseStretchDelay {
  0%, 80% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-opacity: 1;
    opacity: 1;
  }
  45% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    -webkit-opacity: 0.7;
    opacity: 0.7;
  }
}

@keyframes v-pulseStretchDelay {
  0%, 80%{
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-opacity: 1;
    opacity: 1;
  }
  45% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    -webkit-opacity: 0.7;
    opacity: 0.7;
  }
}
