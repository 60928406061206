.organisation-request-box {
  $h: 43px;

  .organisation-box {
    height: 100%;
    width: 100%;

    .action--small {
      &:last-of-type {
        margin-right: 0px;
      }
    }
  }

  .organisation {
    width: calc(100% - 70px);
    margin-right: 10px;
    min-height: $h;
    border: $border-base-1;
    border-radius: $border-radius;
    padding: 0.5rem 0.75rem;
    position: relative;
    padding-right: 15px;
  }

  .organisation-name {
    line-height: 33px;
  }

  .action--small {
    float: right;
  }
}
