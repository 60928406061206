/// Block Modifier
/// @access public
/// @param {String} $modifier - Modifier's name
@mixin modifier($modifier) {
  &--#{$modifier} {
    @content;
  }
}

@mixin m($modifier) {
  @include modifier($modifier) {
    @content;
  }
}

// generic transform
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

// rotate
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
  @include transform(scale($scale));
}

// translate
@mixin translate ($x, $y) {
  @include transform(translate($x, $y));
}

// skew
@mixin skew ($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}

// transform origin
@mixin transform-origin ($origin) {
  moz-transform-origin: $origin;
  -o-transform-origin: $origin;
  -ms-transform-origin: $origin;
  -webkit-transform-origin: $origin;
  transform-origin: $origin;
}

@mixin transition($transition...) {
  -moz-transition:    $transition;
  -o-transition:      $transition;
  -webkit-transition: $transition;
  transition:         $transition;
}

@mixin transition-property($property...) {
  -moz-transition-property:    $property;
  -o-transition-property:      $property;
  -webkit-transition-property: $property;
  transition-property:         $property;
}

@mixin transition-duration($duration...) {
  -moz-transition-duration:    $duration;
  -o-transition-duration:      $duration;
  -webkit-transition-duration: $duration;
  transition-duration:         $duration;
}

@mixin transition-timing-function($timing...) {
  -moz-transition-timing-function:    $timing;
  -o-transition-timing-function:      $timing;
  -webkit-transition-timing-function: $timing;
  transition-timing-function:         $timing;
}

@mixin transition-delay($delay...) {
  -moz-transition-delay:    $delay;
  -o-transition-delay:      $delay;
  -webkit-transition-delay: $delay;
  transition-delay:         $delay;
}

// keyframes mixin
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin animation($animation...) {
  -o-animation: $animation;
  -moz-animation: $animation;
  -webkit-animation: $animation;
  animation: $animation;
}

// Responsive modifier
$mobileWidth: 785px;
$tabletWidth: 899px;
@mixin respondToWidth($media) {
  @if $media == m {
    @media only screen and (max-width: $mobileWidth) {
      @content;
    }
  } @else if $media == t {
    @media only screen and (max-width: $tabletWidth) {
      @content;
    }
  } @else if $media == d {
    @media only screen and (min-width: $tabletWidth) {
      @content;
    }
  }
}

$mobileHeight: 500px;
$tabletHeight: 700px;
@mixin respondToHeight($media) {
  @if $media == m {
    @media only screen and (max-height: $mobileHeight) {
      @content;
    }
  } @else if $media == t {
    @media only screen and (max-height: $tabletHeight) {
      @content;
    }
  } @else if $media == d {
    @media only screen and (min-height: $tabletHeight) {
      @content;
    }
  }
}

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

// center vertically and/or horizontally an absolute positioned element
@mixin center($xy:xy) {
  @if $xy == xy {
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    @include transform(translateX(-50%) translateY(-50%));
  }
  @else if $xy == x {
    left: 50%;
    right: auto;
    @include transform(translateX(-50%));
  }
  @else if $xy == y {
    top: 50%;
    bottom: auto;
    @include transform(translateY(-50%));
  }
}

// border radius
@mixin border-radius($radius:.25em) {
  border-radius: $radius;
}
// }

@mixin showOnHover () {
  @media (hover: hover) {
    .show-on-hover {
      opacity: 0;
      pointer-events: none;
    }
    &:hover .show-on-hover {
      opacity: 1;
      pointer-events: initial;
    }
  }
}