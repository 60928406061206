.container-fluid {
  padding-left: 15px;
  padding-right: 15px;
}

.app-header, .app-footer {
  &_show {
    max-width: $container-max-width;
  }
}

.app-body {
  min-height: calc(100vh - #{$header-height} - #{$footer-height});
  background: $white-dark;

  &_header {
    min-height: calc(#{$nav-height} + #{$sub-header-height});
  }

  &_show {
    max-width: $container-max-width;
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #d8d8d8;
  }
}

.app-warning {
  z-index: 10000;
  position: fixed;
  top: 0px;
  text-align: center;
  width: 100%;
  padding: 5px 10px;
  background: $yellow-light;
  font-size: 0.9em;
  line-height: 1.1em;
  box-shadow: $box-shadow;
}

.app-warning-header {
  top: 25px;
}

.app-warning-body {
  min-height: calc(100vh - #{$header-height} - #{$footer-height} - 25px);
  margin-top: 75px;
}
.card {
  border: none;
  box-shadow: $box-shadow;

  &.card--trend {
    box-shadow: 0px 1px 2px 0px #47505926;
    border: 1px solid $grey-lightest;
  }
}
