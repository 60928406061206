.vdp-datepicker {
  .form-control[readonly] {
    background-color: $white
  }

  .input-group-text {
    background-color: $blue;
    font-size: 0.8em;
    color: $white;
    border-color: $blue;

    &:hover, &:active {
      background-color: $blue-dark;
      border-color: $blue-dark;
    }
  }

  &__calendar {
    border: $border-base-1 !important;
    border-radius: $border-radius !important;

    .cell {
      border-radius: $border-radius !important;

      &:hover {
        border: $border-green-1 !important;
      }
    }

    .cell.selected {
      background: $green-light !important;
      border-radius: $border-radius !important;
      color: $white;

      &:hover {
        border: $border-green-1 !important;
      }
    }
  }
}

.vdp-datepicker.t-placeholder-fake {
  .input-group .form-control {
    color: $text-secondary !important;
  }
}

.vdp-datepicker__calendar header .prev:after {
  border-right: 10px solid $blue !important;
}

.vdp-datepicker__calendar header .next:after {
  border-left: 10px solid $blue !important;
}
