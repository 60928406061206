// Used for $dialog component.
.dg-backdrop {
  background-color: rgba(0, 0, 0, .5) !important;
}

.dg-backdrop, .dg-container {
  z-index: 10010 !important;
}

.dg-main-content {
  border-radius: $border-radius !important;
  padding: 1.5rem !important;
}

.dg-content-body {
  border-bottom: $border-base-1 !important;
  padding-bottom: 1.5rem !important;
}

.dg-content-footer {
  padding-top: 1.5rem !important;
}


.dg-btn--cancel {
  color: $text-regular !important;
  font-weight: 600 !important;
  background: $white !important;
  margin-right: 1rem !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
	border: none;
	min-width: auto;

  &:hover, &:active, &:focus {
    color: $blue !important;
  }
}

.dg-btn--ok {
  @extend .btn-secondary;
  float: right !important;
  border: $border-danger-1 !important;
  min-width: 120px;
  background: $red !important;;
	color: $white !important;

  &:disabled {
    background-color:  $red !important;;
    color: $white !important;
  }
}

.dg-content {
  line-height: 1.6em !important;
  font-size: 1em !important;
}

.dg-container--has-input .dg-content-footer {
	background: transparent;
	border: none;
	padding: 0;
	padding-top: 1rem !important;
}

.dg-form label {
	font-size: 1em !important;
}

.dg-container--has-input .dg-form {
	background: transparent;
	border: none;
	padding: 0;
	padding-bottom: 1rem !important;
}

.dg-btn-loader .dg-circle {
	height: 0.5em !important;
	width: 0.5em !important;
	background-color: $white !important;
}