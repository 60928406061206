.login-page-container {
  .align-items-center {
    min-height: calc(100vh);
  }

  .fa-background {
    color: white;
    font-size: 40em;
    position: absolute;
    top: calc(50% + #{$header-height});
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.05;
  }

  .al-logo-main {
    transform: rotate(90deg);
  }

  .btn-secondary {
    white-space: normal;
    &:hover {
      a {
        text-decoration: none;
      }
    }
  }

  .get-started {
    position: absolute;
    top: 0;
    right: 0;
  }

  // mobile changes
  @include respondToWidth(t) {
    height: 100vh;
    background: $white-dark;

    h1 {
      height: $header-height;
      padding: 15px 0px;
    }

    .align-items-center {
      align-items: normal !important;
    }

    .min-height--900px {
      min-height: auto;
    }

    hr.mb-5 {
      margin-bottom: 1.5rem !important;
    }

    .min-height--100vh {
      height: auto;
      background: $white-dark;
    }

    .text-help {
      position: relative;
      bottom: auto;
      width: auto;
      background: $white-dark;

      p {
        margin: 0px !important;
        padding: 30px 0px 15px 0px;
      }
    }

    .get-started {
      top: -2.5rem;
      right: auto;
      left: 15px;
    }

    .al-logo-main {
      display: none;
    }
  }
}

.centered-text-hr {
  width: 100%;
  // height: 30px;

  hr {
    width: 40%;
    margin-top: 13px !important;
  }

  p {
    width: 20%;
  }

  hr, p {
    float: left;
  }
}

.fa-large {
  font-size: 2em;
}

#signup {
  .app-header {
    background: $white;

    .navbar-brand {
      color: $blue;
    }
  }

  .container-fluid.pg-w {
    margin-top: $header-height;
  }
}
