.br-bl {
  border-color: $blue !important;
}

.bd {
  border: $border-base-1;
}

.bd-r {
  border-right: $border-base-1;
}

.bd-l {
  border-left: $border-base-1;
}

.bd-b {
  border-bottom: $border-base-1 !important;
}

.bd-t {
  border-top: $border-base-1 !important;
}

.bd-tx2 {
  border-top: $border-base-2 !important;
}

.bd-x {
  border-left: $border-base-1;
  border-right: $border-base-1;
}

.bd-y {
  border-top: $border-base-1;
  border-bottom: $border-base-1;
}

.bd-none {
  border: none !important;
}

.bd-t-none {
  border-top: none !important;
}

.bd-radius {
  border-radius: $border-radius;
}

.bd-striped {
  border: 3px dashed $grey-lightest;
}

.bd-color-yellow {
  border-color: $yellow !important;
}

.bd-color-green {
  border-color: $green !important;
}

.bd-color-green-light {
  border-color: $green-light !important;
}

.bd-color-light {
  border-color: $grey-lightest;
}

.bd-color-blue {
  border-color: $blue;
}

.bd-color-red {
  border-color: $red;
}

.bd-color-white {
  border-color: $white !important;
}