@keyframes ringing {
  0%, 100% {
    transform: rotate(0)
  }
  20% {
    transform: rotate(10deg)
  }
  40%, 80% {
    transform: rotate(-6deg);
  }
  60% {
    transform: rotate(6deg);
  }
}

@keyframes clapp {
  $_x: 6px;

  0%, 100% {
    transform: translate($_x, 18px) rotate(0);
  }
  20% {
    transform: translate($_x + 4px, 18px) rotate(-10deg);
  }
  40% {
    transform: translate($_x - 4px, 18px) rotate(10deg);
  }
  60% {
    transform: translate($_x + 3px, 18px) rotate(-8deg);
  }
  80% {
    transform: translate($_x - 3px, 18px) rotate(8deg);
  }
}

@mixin hidden-visually () {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.clap-animating {
  animation: ringing 800ms;
}

.fade-alert {
  transition: color 0.3s;

  &.fade-alert-in {
    color: $orange;
  }
}
