.cookie-notification-container {
  position: fixed;
  bottom: 0;
  width: 100vw;
  background-color: $blue;
  color: white;
  z-index: 2;
}

.cookie-notification-inner {
  max-width: $container-max-width;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin: auto;
  display: flex;
  justify-content: space-between;

  .cookie-notification-text {
    margin-right: 6rem;

    a {
      color: $white;
      text-decoration: underline;
    }
  }

  // mobile changes
  @include respondToWidth(m) {
    padding-top: 15px;
    padding-bottom: 15px;
    flex-wrap: wrap;
    justify-content: start;

    .cookie-notification-text {
      margin-right: 0;
    }
  }
}