<template>
    <div class="callout">
      <div v-if="icon" class="mr-2" >
        <i :class="icon"></i>
      </div>
      <div class="content">
        <slot></slot>
      </div>
    </div>
  </template>

<script>
/**
 * A simple call-out component to give extra attention to a piece of content.
 *
 * Note: it doesn't have a `background-color` out of the box. It's recommended
 * to set one by setting the style or class-attribute on the <callout>-tag.)
 */
export default {
  name: 'Callout',
  props: {
    icon: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
  .callout {
  border-radius: 0.25rem;
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  }
  .content {
  flex-grow: 1;
  }
</style>
