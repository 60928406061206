
// #error-page-403 {
//   .error-page-container {
//     height: calc(100vh - #{$header-height} - #{$footer-height});
//   }
// }

.error-page {
  .app-body_show .col-md-5 {
    padding-top: calc(#{$header-height} * 1.5);
    height: 100%;
  }

  .app-footer {
     a {
       color: $white;
     }
  }

  .t-xlr {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }

  .cta-group .btn-secondary {
    width: 45%;
    display: inline-block;
    border: $border-white-1;

    &:nth-child(odd) {
      margin-right: 5%;
      background: none;
    }
  }

  .report {
    a {
      color: $white;
      text-decoration: underline;

      &:hover {
        color: $yellow;
      }
    }
  }
}