.speech-bubble {
  color: $text-regular;
  background: $white;

  position: relative;

  padding: 20px;
  border-radius: 16px;
  max-width: 380px;
  margin: 20px auto;
}

.speech-bubble::after {
  /* (B1-1) ATTACH TRANSPARENT BORDERS */
  content: "";
  border: 12px solid transparent;
  position: absolute;

  /* (B2-1) DOWN TRIANGLE */
  border-top-color: $white;
  border-bottom: 0;

  /* (B2-2) POSITION AT BOTTOM */
  bottom: -12px; left: 50%;
  margin-left: -12px;
}