.email-company:focus + .tooltip-custom,
.email-academic:focus + .tooltip-custom {
    display: block;
    -webkit-animation: fadeIn 0.5s;
    animation: fadeIn 0.5s;
}

@-webkit-keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}  
@keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}