// blues
$blue-lightest: #ecf3fc;
$blue-light: #43a0dd;
$blue-inactive: #9cd1df;
$blue: #42AEC6;
$blue-dark: #369cb8;
$blue-visited: #6c499a;

$blue-green: #42aec5;

// greens
$green-light: #3ece8f;
$green: #37bcae;

// reds
$red: #ff6960;
$red-light: #FFE5E4;
$orange: #ff8f00;
$pink: #EFA9BB;

// yellows
$yellow-light: #fcf8e3;
$yellow: #fcc419;

// whites
$white: #ffffff;
$white-light: #FCFCFC;
$white-dark: #F5F7F7;

// greys
$grey-lightest: #e8e8ec;
$grey-lighter: #bfccda;
// $grey-light: #aebdcc;
$grey-light: #AEC5CC;
$grey-semi-light: #E3E3E7;
$grey-bg-light: #EFF1F4;
$grey: #656f78;
$grey-dark: #475258;

// black
$black: #000;

// transparent
$blue-transparent: rgba(59, 153, 240, 0.1);
$black-transparent: rgba(255, 255, 255, 0.2);
$black-transparent-2: rgba(255, 255, 255, 0.4);
$black-transparent-3: rgba(255, 255, 255, 0.6);
$grey-transparent: rgba(101, 111, 120, 0.9);

// text
$text-yellow: #fede7e;
$text-primary: $grey-dark;
$text-regular: $grey;
$text-secondary: $grey-light;
$text-secondary-transparent: rgba(174, 189, 204, 0.5);
$text-secondary-light: $grey-lightest;
$text-placeholder: $grey-lighter;

// border
$border-base: $grey-lightest;

// Making the RGB triples of some colours available as CSS variables
// (lets you tweak their opacity inside style attributes of Vue templates)
:root {
    --text-primary: #{red($text-primary)}, #{green($text-primary)}, #{blue($text-primary)};
    --yellow: #{red($yellow)}, #{green($yellow)}, #{blue($yellow)};
    --blue: #{red($blue)}, #{green($blue)}, #{blue($blue)};
  }
