.modal {
  display: block;
}

.modal-mask {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  transition: opacity .3s ease;
}

.modal-dialog-l {
  max-width: 850px;
  width: 850px;

  .modal-body {
    max-height: 90vh;
    overflow-y: auto;
  }

  @include respondToWidth(t) {
    max-width: 90%;
    width: 90%;
  }
}

.modal-dialog-xl {
  max-width: 90%;
  width: 1100px;

  .modal-body {
    max-height: 90vh;
    overflow-y: auto;
  }

  @include respondToWidth(t) {
    max-width: 90%;
    width: 90%;
  }
}

.modal-dialog-fs {
  max-width: 90%;
  width: 90%;
  margin: 5vh 5%;

  .modal-body {
    overflow-y: auto;
  }

  @include respondToWidth(t) {
    max-width: calc(100% - 30px);
    width: calc(100% - 30px);
    margin: 5vh 15px;
  }
}

.modal-dialog, .modal-dialog-fs {
  overflow-y: initial !important;

  .modal-body {
    max-height: 90vh;
    overflow-y: auto;
  }
}

.modal-content {
  border-radius: $border-radius;
  max-height: 90vh;
}

.close-dialog {
  position: absolute;
  z-index: 1;
  right: -12px;
  top: -12px;
  color: $blue;
  background: $white-dark;
  border: $border-base-1;
  width: 24px;
  height: 24px;
  border-radius: 48px;
  line-height: 21px;
  font-size: 0.7em !important;
  text-align: center;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    background: $white;
    cursor: pointer;
  }
}