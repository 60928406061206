.step-list {
  text-align: center;
  max-width: 100%;

  ul {
    margin: 0;
    padding: 0;
    line-height: 0;
    margin-bottom: 23px;
  }

  .step-list-item {
    margin: 0px 5px;

    // &:first-of-type {
    //   margin-left: 0px !important;
    // }
    
    // &:last-of-type {
    //   margin-right: 0px !important;
    // }

    @include respondToWidth(m) {
      display: none;
    }
  }

  &-final li {
    width: 100%;
    display: none;

    &:first-child {
      display: block;
    }
  }

  .step-list-item-text {    
    .step-index {
      height: auto;
      border-radius: 3rem;
      padding: 5px 50px;
      font-size: 0.9em;
      color: $white;
      font-weight: 600;
      line-height: 1em;
    }
  }
}

.step-icon {
  margin-bottom: 10px;
}

.step-index {
  display: inline-block;
  height: 5.8px;
  border-radius: 2.9px;
  background-color: #cde2ef;

  &-active {
    background-color: $green;

    &:hover {
      cursor: pointer;
      background-color: $blue;
    }
  }

  &:last-of-type {
    margin-right: 0% !important;
  }
}

.step-list-final .step-index-active:hover {
  cursor: default !important;
  background-color: $green !important;
}

.step-list-final {
  .step-index {
    width: 100% !important;
  }
}
