.tooltip-custom {
	box-shadow: $box-shadow;
	box-sizing: border-box;
	border-radius: 3px;
	position: relative;
	width: 100%;
	z-index: 3;

	&:before {
    box-sizing: border-box;
    box-shadow: -3px 3px 3px -3px rgba(25, 25, 25, 0.1);
    border: 8px solid black;
    border-color: transparent transparent $white $white;
    content: "";
    height: 0;
    left: 42px;
    position: absolute;
    top: 0;
    transform-origin: 0 0;
    transform: rotate(-225deg);
    width: 0;
	}

	p {
		line-height: 1.57;
	}
}
