.horizontal-list {
  position: relative;
  width: 100%;
  .btn {
    position: absolute;
    background: $white-dark;
    padding: 0px 8px;
    cursor: pointer;

    &-left{
      float: left;
      left: 0;
    }
    &-right{
      float: right;
      right: 0;
    }
  }

  &--items {
    float: left;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;

    .item{
      display: inline-block;
    }
  }
}
