.account-completion {
  @include respondToWidth(m) {
    display: none;
  }

  li.is-hover {
    &:hover {
      cursor: pointer;
      color: $blue;
    }
  }

  h3 .text {
    margin-top: 15px;
  }

  .rounded-circle {
    width: 30px;
    height: 30px;

    i {
      padding-left: 2px;
      line-height: 29px;
      width: 27px;
    }
  }

  .rounded-circle-text {
    line-height: 30px;
    width: calc(100% - 30px - 0.5rem);
  }
}
